<template>
  <div class="charging-home">
    <!-- first line -->
    <el-row class="home-row first" :gutter="20">
      <!-- 地图 -->
      <el-col :span="12">
        <homeMap />
      </el-col>
      <!-- 经销商 -->
      <el-col :span="12">
        <homeCardR1C2 />
      </el-col>
    </el-row>
    <!-- second line -->
    <el-row class="home-row second" :gutter="20">
      <el-col :span="6">
        <homeCardR2C1 />
      </el-col>
      <el-col :span="6">
        <homeCardR2C2 />
      </el-col>
      <el-col :span="6">
        <homeCardR2C3 />
      </el-col>
      <el-col :span="6">
        <homeCardR2C4 />
      </el-col>
    </el-row>

  </div>
</template>
<script>
 export default {
   name: 'home'
 }
</script>
<script setup>
import homeCardR2C1 from './components/home-cards/r2c1.home-card.vue';
import homeCardR2C2 from './components/home-cards/r2c2.home-card.vue';
import homeCardR2C3 from './components/home-cards/r2c3.home-card.vue';
import homeCardR2C4 from './components/home-cards/r2c4.home-card.vue';
import homeCardR1C2 from './components/home-cards/r1c2.home-card.vue';
import homeMap from './components/home-map';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
// import homeCardR1C3 from './components/home-cards/r1c3.home-card.vue';
// import homeCardR2C2I1 from './components/home-cards/r2c2i1.home-card.vue';
const store = useStore();

onMounted(() => {
  store.dispatch('home/sync_home_data');
});

</script>
<style lang="scss">
.charging-home {
  --home-space: 2rem;
}
.charging-home {
  height: calc(100% - var(--charging-pile-size-header-height));
  padding: var(--home-space);
  background-color: #F5F7FB;
  /* 首页卡片公公样式 */
  .home-card {
    background: #FFFFFF;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    padding: var(--home-space);

    .home-card-title {
      font-size: 1.4rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: .8rem;
      display: flex;
      justify-content: space-between;
    }
  }

  /* 首页布局样式 */
  .home-row {
    .el-col {
      height: 100%;
    }

    &.second {
      margin-top: var(--home-space);
      height: calc(((100% - var(--home-space)) / 4) * 1)
    }

    &.first {
      height: calc(((100% - var(--home-space)) / 4) * 3)
    }

    .home-inner-card-col {
      height: calc((100% - var(--home-space)) / 3);
    }
    .home-inner-card-col + .home-inner-card-col {
      margin-top: var(--home-space);
    }
  }
}
</style>

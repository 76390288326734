import {createApp} from 'vue';
import App from './App.vue';
import router, {set_router_guard} from './router';
import store from './store';

// Styles
import '@/assets/styles/reset.css';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn';
// import 'element-plus/dist/index.css';
import '@/assets/styles/base.theme.scss';
import '@/assets/styles/variable.css';
import '@/assets/styles/el-override.scss';
import '@/assets/styles/media-queries.scss';
import '@/assets/styles/base.scss';
import {
  CircleCheck,
  Cellphone,
  QuestionFilled,
  Tools,
  CirclePlus,
  AlarmClock,
  Message,
  CaretTop,
  StarFilled,
  CaretBottom,
  CircleCheckFilled,
  ArrowUp,
  ArrowLeft,
  ArrowDown,
  ArrowRight,
  ArrowLeftBold,
  CircleCloseFilled,
  Headset,
  MostlyCloudy,
  Film,
  InfoFilled,
  Tickets,
  Search,
  Download,
  Document,
  Setting,
  PictureFilled,
  Remove,
  Ticket,
  Loading,
  Checked,
  CloseBold,
  Coin,
  Files,
  Select,
  HomeFilled,
  HelpFilled,
  ShoppingBag,
  Bell,
  Burger,
  Plus,
  Delete,
  Edit,
  DataLine,
  Monitor,
  Upload,
  Menu,
  Cpu,
  TrendCharts,
} from '@element-plus/icons';

// Components
import fcTable from '@/components/fc-table';
import dialogDrag from '@/utils/directive/el-dragDialog/dialogDrag'

// Directive
import v_permission from '@/directives/v-permission.directive.js';

// Video
// import 'vue3-video-play/dist/style.css';
// import vue3videoPlay from 'vue3-video-play'; // 引入组件

// Map
// import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap';
// import '@vuemap/vue-amap/dist/style.css';

// Config
import app_config from './config/app.config';

// import '@/mock';

// set router
set_router_guard(router, store);

// initAMapApiLoader({ key: app_config.map.key });
// console.log(process.env);

const app = createApp(App);
app
    .use(store)
    .use(router)
    .use(ElementPlus, {locale})
    .use(dialogDrag)
    // .use(VueAMap)
    .provide('app_config', app_config)
    .provide('env', process.env);

// set global icons
/*
*  global icons 标签用来适配需要动态展示icon的场景:
*
*  <el-icon>
*    <component :is="m.icon" />
*  </el-icon>
*/
app.component('setting', Setting);
app.component('ticket', Ticket);
app.component('circleCheckFilled', CircleCheckFilled);
app.component('homeFilled', HomeFilled);
app.component('helpFilled', HelpFilled);
app.component('shoppingBag', ShoppingBag);
app.component('questionFilled', QuestionFilled);
app.component('circleCloseFilled', CircleCloseFilled);
app.component('bell', Bell);
app.component('plus', Plus);
app.component('delete', Delete);
app.component('burger', Burger);
app.component('edit', Edit);
app.component('dataLine', DataLine);
app.component('right', Select);
app.component('closeBold', CloseBold);
app.component('loading-ani', Loading);
app.component('files', Files);
app.component('coin', Coin);
app.component('document', Document);
app.component('checked', Checked);
app.component('pictureFilled', PictureFilled);
app.component('remove', Remove);
app.component('download', Download);
app.component('search-icon', Search);
app.component('tickets', Tickets);
app.component('infoFilled', InfoFilled);
app.component('film', Film);
app.component('mostlyCloudy', MostlyCloudy);
app.component('headset', Headset);
app.component('arrowLeftBold', ArrowLeftBold);
app.component('arrowDown', ArrowDown);
app.component('arrowLeft', ArrowLeft);
app.component('arrowRight', ArrowRight);
app.component('arrowUp', ArrowUp);
app.component('alarmClock', AlarmClock);
app.component('message', Message);
app.component('cellphone', Cellphone);
app.component('circleCheck', CircleCheck);
app.component('upload', Upload);
app.component('menu', Menu);
app.component('cpu', Cpu);
app.component('trendCharts', TrendCharts);
// app.component('view', View);
// app.component('hide', Hide);
app.component('monitor', Monitor);
app.component('caretTop', CaretTop);
app.component('tools', Tools);
app.component('circlePlus', CirclePlus);
app.component('caretBottom', CaretBottom);
app.component('starFilled', StarFilled);
// 注册常用组件
app.component('fcTable', fcTable);
// app.use(vue3videoPlay);
// 注册Directive
v_permission(app, store);

app.mount('#app');

<template>
  <el-drawer v-model="show_drawer" direction="rtl">
    <template #title>
      <h4>消息列表</h4>
    </template>
    <template #default>
      <div class="charging-pile-messages">
        charging-pile-messages
      </div>
    </template>
  </el-drawer>
</template>
<script setup>
import { ref } from 'vue';

const show_drawer = ref(false);

// expose
const open = () => {
  show_drawer.value = true;
};

defineExpose({
  open
});
</script>
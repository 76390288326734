import product from '@/views/main/modules/product';
import data_form from '@/views/main/modules/data-form';
import system from '@/views/main/modules/system';
import advertising from '@/views/main/modules/advertising';
import operation_center from '@/views/main/modules/operation-center';
import feedback from '@/views/main/modules/feedback';
import finance from '@/views/main/modules/finance';
// import log from '@/views/main/modules/log';
import qrcode from '@/views/main/modules/qrcode';
import ota from '@/views/main/modules/ota';
import home from '@/views/main/modules/home';
import coupon from '@/views/main/modules/coupon';
import monitoring from '@/views/main/modules/monitoring';
import workflow_center from '@/views/main/modules/workflow-center';
import maintenance from '@/views/main/modules/maintenance'
import zc from '@/views/main/modules/zc';

export default {
  name: '运营平台',
  modules: [
    {
      name: '首页',
      path: '/home',
      icon: 'homeFilled',
      link: '/home',//用于添加 keep alive缓存，添加tab列表，与完整路由一致
      com_name: 'home',//用于清除 keep alive缓存，要与页面 组件name一致
      perm: 'ops-menu-home-view',
      component: home
    },
    {
      name: '数据座舱',
      // path: 'https://xwx.nbswift.cn/dv/monitor',
      icon: 'helpFilled',
      component: zc,
      is_not_menu_path: true,
      path: '/zc',
      children: [
        {
          name: '数据监控平台',
          perm: 'ops-menu-data-view',
          icon: 'helpFilled',
          open_tab: true,
          path: 'dv/monitor'
        },
        {
          name: '数据概览平台',
          perm: 'ops-menu-data-view',
          icon: 'helpFilled',
          open_tab: true,
          path: 'dv/statistic'
        }
      ]
    },

    {
      name: '运营中心',
      component: operation_center,
      path: '/operation-center',
      icon: 'dataLine',
      children: [
        {
          name: '经销商管理',
          path: 'agent',
          perm: 'ops-menu-agent',
          link: '/operation-center/agent',
          com_name: 'agent-manager',
          component: () => import('@/views/main/modules/operation-center/agent'),
          extra_routes: [
            {
              path: 'agent/ledgerManagement',
              component: () => import('@/views/main/modules/operation-center/agent/components/ledger-management'),
            },
            {
              path: 'agent/withdrawalManagement',
              component: () => import('@/views/main/modules/operation-center/agent/components/withdrawal-management'),
            },
            {
              path: 'agent/fullBalance',
              component: () => import('@/views/main/modules/operation-center/agent/components/full-balance'),
            },
          ]
        },
        {
          name: '充电桩管理',
          path: 'pile',
          perm: 'ops-menu-pile',
          link: '/operation-center/pile',
          com_name: 'pile-manager',
          component: () => import('@/views/main/modules/operation-center/pile'),
          extra_routes: [
            {
              path: 'pile/detail/:pile_id',
              component: () => import('@/views/main/modules/operation-center/pile/detail')
            },
            {
              path: 'pile/data-view/:pile_id',
              component: () => import('@/views/main/modules/operation-center/pile/data-view')
            }
          ]
        },
        {
          name: '设备列表',
          path: 'device',
          perm: 'ops-menu-device-list',
          link: '/operation-center/device',
          com_name: 'device-list',
          component: () => import('@/views/main/modules/operation-center/device'),
          extra_routes: [
            {
              path: 'device/data-view/:pile_id',
              component: () => import('@/views/main/modules/operation-center/device/data-view')
            }
          ]
        },
        {
          name: '个人桩管理',
          path: 'person',
          perm: 'ops-menu-personal-pile',
          link: '/operation-center/person',
          com_name: 'person-pile-manager',
          component: () => import('@/views/main/modules/operation-center/person'),
          extra_routes: [
            {
              path: 'person/data-view/:user_id',
              component: () => import('@/views/main/modules/operation-center/person/data-view')
            }
          ]
        },
        // {
        //   name: '平台管理',
        //   path: 'platform',
        //   perm: 'ops-menu-agent',
        //   component: () => import('@/views/main/modules/operation-center/platform'),
        //   extra_routes: [
        //     {
        //       path: 'platform/created',
        //       component: () => import('@/views/main/modules/operation-center/platform/data-view/create')
        //     },
        //     {
        //       path: 'platform/data-view/:user_id',
        //       component: () => import('@/views/main/modules/operation-center/platform/data-view')
        //     }
        //   ]
        // },
      ]
    },
    {
      name: '营销中心',
      component: coupon,
      path: '/coupon',
      icon: 'ticket',
      children: [
        {
          name: '优惠券模版',
          path: 'template',
          perm: 'ops-menu-coupon-template',
          link: '/coupon/template',
          com_name: 'coupon-template',
          component: () => import('@/views/main/modules/coupon/coupon-template')
        },
        {
          name: '优惠券数据',
          path: 'data',
          perm: 'ops-button-coupon-data',
          link: '/coupon/data',
          com_name: 'coupon-data',
          component: () => import('@/views/main/modules/coupon/coupon-data')
        },
        {
          name: '充值优惠',
          path: 'recharge',
          perm: 'ops-menu-coupon-recharge',
          com_name: 'coupon-recharge',
          link: '/coupon/recharge',
          component: () => import('@/views/main/modules/coupon/coupon-recharge')
        }
      ]
    },
    {
      name: '审批中心',
      path: '/workflow-center',
      component: workflow_center,
      icon: 'cellphone',
      perm: 'ops-menu-workflow-center',
      children: [
        {
          name: '审批管理',
          path: 'workflow',
          perm: 'ops-menu-workflow-center',
          link: '/workflow-center/workflow',
          com_name: 'approval-management',
          component: () => import('@/views/main/modules/workflow-center/workflow'),
          extra_routes: [
            {
              path: 'workflow/detail/:workflow_id',
              component: () => import('@/views/main/modules/workflow-center/workflow/details/workflow-detail')
            }
          ]
        }
      ]
    },
    {
      name: '广告管理',
      component: advertising,
      path: '/advertising',
      icon: 'shoppingBag',
      children: [
        {
          name: '广告商管理',
          path: 'advert-compnay',
          perm: 'ops-menu-advert-compnay',
          link: '/advertising/advert-compnay',
          com_name: 'advert-manager',
          component: () => import('@/views/main/modules/advertising/advert-compnay')
        },
        {
          name: '广告内容审核',
          path: 'advert-content',
          perm: 'ops-menu-advert-content',
          link: '/advertising/advert-content',
          com_name: 'advert-auditing',
          component: () => import('@/views/main/modules/advertising/advert-content')
        },
        {
          name: '节目单管理',
          path: 'advert-program',
          perm: 'ops-menu-advert-program',
          link: '/advertising/advert-program',
          com_name: 'program-manager',
          component: () => import('@/views/main/modules/advertising/advert-program'),
          extra_routes: [
            {
              path: 'advert-program/detail/:program_id',
              component: () => import('@/views/main/modules/advertising/advert-program/detail')
            }
          ]
        },
        {
          name: '广告任务下发',
          path: 'advert-task',
          perm: 'ops-menu-advert-task',
          link: '/advertising/advert-task',
          com_name: 'advert-task-distribute',
          component: () => import('@/views/main/modules/advertising/advert-task'),
          extra_routes: [
            {
              path: 'advert-task/detail/:task_id',
              component: () => import('@/views/main/modules/advertising/advert-task/detail')
            }
          ]
        },
        {
          name: '小程序广告',
          path: 'advert-miniprogram',
          perm: 'ops-menu-mp-ad',
          link: '/advertising/advert-miniprogram',
          com_name: 'mini-program-advert',
          component: () => import('@/views/main/modules/advertising/advert-miniprogram')
        }
      ]
    },
    {
      name: '财务管理',
      component: finance,
      path: '/finance',
      icon: 'coin',
      children: [
        {
          name: '提现管理',
          path: 'withdrawal',
          perm: 'ops-menu-withdral-mgt',
          link: '/finance/withdrawal',
          com_name: 'withdrawal-manager',
          component: () => import('@/views/main/modules/finance/withdrawal')
        },
        {
          name: '发票管理',
          path: 'invoice',
          perm: 'ops-menu-invoice',
          link: '/finance/invoice',
          com_name: 'invoice-manager',
          component: () => import('@/views/main/modules/finance/invoice')
        },
        {
          name: '充值记录管理',
          path: 'recharge',
          perm: 'ops-menu-finance-recharge',
          link: '/finance/recharge',
          com_name: 'recharge-manager',
          component: () => import('@/views/main/modules/finance/recharge')
        },
        {
          name: '订单管理',
          path: 'order',
          perm: 'ops-menu-order',
          link: '/finance/order',
          com_name: 'order-manager',
          component: () => import('@/views/main/modules/finance/order'),
          extra_routes: [
            {
              path: 'order/data-view/:order_id',
              component: () => import('@/views/main/modules/finance/order/detail.vue')
            },
          ]
        },
        {
          name: '收支管理',
          path: 'transaction',
          perm: 'ops-menu-transaction',
          link: '/finance/transaction',
          com_name: 'transaction-manager',
          component: () => import('@/views/main/modules/finance/transaction')
        },
        {
          name: '平台对账',
          path: 'wxaccount',
          perm: 'ops-menu-wechat-account',
          link: '/finance/wxaccount',
          com_name: 'platform-reconciliation',
          component: () => import('@/views/main/modules/finance/wxAccount')
        },
        {
          name: '度普对账',
          path: 'dpaccount',
          perm: 'ops-menu-dupe-checked',
          link: '/finance/dpaccount',
          com_name: 'dp-reconciliation',
          component: () => import('@/views/main/modules/finance/dpAccount')
        },
        {
          name: '订单处理',
          path: 'exceptionorder',
          perm: 'ops-menu-exception-order',
          link: '/finance/exceptionorder',
          com_name: 'order-handle',
          component: () => import('@/views/main/modules/finance/exceptionOrder'),
          extra_routes: [
            {
              path: 'exceptionOrder/data-view/:order_id',
              component: () => import('@/views/main/modules/finance/exceptionOrder/data-view/index')
            },
            {
              path: 'exceptionOrder/data-exception/:order_id/:type?',
              component: () => import('@/views/main/modules/finance/exceptionOrder/data-exception/index')
            }
          ]
        }
      ]
    },
    {
      name: '反馈管理',
      component: feedback,
      path: '/feedback',
      icon: 'message',
      children: [
        {
          name: '工单管理',
          path: 'work-order',
          perm: 'ops-menu-feedback',
          link: '/feedback/work-order',
          com_name: 'feedback-work-manager',
          component: () => import('@/views/main/modules/feedback/work-order'),
          extra_routes: [
            {
              path: 'work-order/process/:wo_id',
              component: () => import('@/views/main/modules/feedback/work-order/process')
            }
          ]
        }
      ]
    },
    // {
    //   name: '日志管理',
    //   component: log,
    //   path: '/log',
    //   icon: 'document',
    //   children: [
    //     {
    //       name: '订单日志',
    //       path: 'order-log',
    //       perm: 'ops-menu-log',
    //       component: () => import('@/views/main/modules/log/order-log')
    //     }
    //   ]
    // },
    // {
    {
      name: '维修管理',
      component: maintenance,
      path: '/maintenance',
      icon: 'checked',
      children: [
        {
          name: '维修工单',
          path: 'repair-order',
          perm: 'ops-menu-repair-order',
          link: '/maintenance/repair-order',
          com_name: 'repair-order',
          component: () => import('@/views/main/modules/maintenance/repair-order'),
          extra_routes: [
            {
              path: 'repair-order/maintenance-detail',
              component: () => import('@/views/main/modules/maintenance/repair-order/detail.vue')
            }
          ]
        },
        {
          name: '项目经理任务',
          path: 'work-order',
          perm: 'ops-menu-work-order-processing',
          link: '/maintenance/work-order',
          com_name: 'work-order-processing',
          component: () => import('@/views/main/modules/maintenance/work-order'),
          extra_routes: [
            {
              path: 'work-order/maintenance-detail',
              component: () => import('@/views/main/modules/maintenance/work-order/detail.vue')
            }
          ]
        },
        {
          name: '工厂任务',
          path: 'factory-tasks',
          perm: 'ops-menu-factory-tasks',
          link: '/maintenance/factory-tasks',
          com_name: 'factory-tasks',
          component: () => import('@/views/main/modules/maintenance/factory-tasks'),
          extra_routes: [
            {
              path: 'factory-tasks/maintenance-detail',
              component: () => import('@/views/main/modules/maintenance/factory-tasks/detail.vue')
            }
          ]
        },
        {
          name: '维修人员安排情况',
          path: 'personnel-arrangement',
          perm: 'ops-menu-personnel-arrangement',
          link: '/maintenance/personnel-arrangement',
          com_name: 'personnel-arrangement',
          component: () => import('@/views/main/modules/maintenance/personnel-arrangement'),
        },
        {
          name: '工单失效模式统计',
          path: 'failure-mode',
          perm: 'ops-menu-failure-mode-statistics',
          link: '/maintenance/failure-mode',
          com_name: 'failure-mode',
          component: () => import('@/views/main/modules/maintenance/failure-mode'),
        },
        {
          name: '人员工作量统计',
          path: 'personnel-workload',
          perm: 'ops-menu-personnel-workload-statistics',
          link: '/maintenance/personnel-workload',
          com_name: 'personnel-workload',
          component: () => import('@/views/main/modules/maintenance/personnel-workload'),
        },
        {
          name: '工单节点处理时长',
          path: 'node-processing',
          perm: 'ops-menu-node-processing-time',
          link: '/maintenance/node-processing',
          com_name: 'node-processing-time',
          component: () => import('@/views/main/modules/maintenance/node-processing'),
        },
      ]
    },
    {
      name: '监控中心',
      component: monitoring,
      path: '/monitoring',
      icon: 'alarmClock',
      children: [
        {
          name: '设备异常',
          path: 'pile-fault',
          // ops-menu-pile-fault
          perm: 'ops-menu-pile-fault',
          link: '/monitoring/pile-fault',
          com_name: 'pile-fault',
          component: () => import('@/views/main/modules/monitoring/pile-fault')
        },
        {
          name: '设备异常汇总',
          path: 'pile-fault-sum',
          perm: 'ops-menu-pile-fault',
          link: '/monitoring/pile-fault-sum',
          com_name: 'pile-fault-sum',
          component: () => import('@/views/main/modules/monitoring/pile-fault-sum')
        },
        {
          name: '故障监控',
          path: 'pile-fault-monitoring',
          perm: 'ops-menu-fault-monitoring',
          link: '/monitoring/pile-fault-monitoring',
          com_name: 'pile-fault-monitoring',
          component: () => import('@/views/main/modules/monitoring/fault-monitoring'),
          extra_routes: [
            {
              path: 'pile-fault-monitoring/fault-detail',
              component: () => import('@/views/main/modules/monitoring/fault-monitoring/fault-detail.vue')
            }
          ]
        },
        {
          name: '故障统计',
          path: 'pile-fault-statistics',
          perm: 'ops-menu-fault-statistics',
          link: '/monitoring/pile-fault-statistics',
          com_name: 'pile-fault-statistics',
          component: () => import('@/views/main/modules/monitoring/fault-statistics'),
          extra_routes: [
            {
              path: 'pile-fault-statistics/fault-statistics-detail',
              component: () => import('@/views/main/modules/monitoring/fault-statistics/fault-statistics-detail.vue')
            }
          ]
        },
        {
          name: '离线统计',
          path: 'pile-offline-statistics',
          perm: 'ops-menu-offline-statistics',
          link: '/monitoring/pile-offline-statistics',
          com_name: 'pile-offline-statistics',
          component: () => import('@/views/main/modules/monitoring/offline-statistics'),
          extra_routes: [
            {
              path: 'pile-offline-statistics/offline-statistics-detail',
              component: () => import('@/views/main/modules/monitoring/offline-statistics/offline-statistics-detail.vue')
            }
          ]
        },
      ]
    },
    {
      name: '二维码管理',
      component: qrcode,
      path: '/qrcode',
      icon: 'pictureFilled',
      children: [
        {
          name: '申请列表',
          path: 'list',
          perm: 'ops-menu-qrcode',
          link: '/qrcode/list',
          com_name: 'qrcode-list',
          // component: () => import('@/views/main/modules/qrcode/list/list.vue'),
          component: () => import('@/views/main/modules/qrcode/code-list'),
          extra_routes: [
            {
              path: 'list/record',
              component: () => import('@/views/main/modules/qrcode/code-list/export-record.vue')
            }
          ]
        },
        {
          name: '二维码关系查询',
          path: 'relation-select',
          perm: 'ops-menu-qrcode-relationship-query',
          link: '/qrcode/relation-select',
          com_name: 'qrcode-relation-select',
          // component: () => import('@/views/main/modules/qrcode/list/list.vue'),
          component: () => import('@/views/main/modules/qrcode/relation-select'),
        }
      ]
    },
    {
      name: 'OTA升级',
      component: ota,
      path: '/ota',
      icon: 'mostlyCloudy',
      children: [
        {
          name: '升级包管理',
          path: 'package',
          perm: 'ops-menu-ota-package',
          link: '/ota/package',
          com_name: 'ota-package',
          component: () => import('@/views/main/modules/ota/ota-package')
        },
        {
          name: '升级发布',
          path: 'publish',
          perm: 'ops-menu-upgrade-release',
          com_name: 'ota-publish',
          component: () => import('@/views/main/modules/ota/ota-publish'),
          link: '/ota/publish',
          extra_routes: [
            {
              path: 'publish/add-publish',
              component: () => import('@/views/main/modules/ota/ota-publish/add-publish')
            },
            {
              path: 'publish/publish-detail',
              component: () => import('@/views/main/modules/ota/ota-publish/publish-detail')
            }
          ]
        },
        // {
        //   name: '测试发布',
        //   path: 'qa-publish',
        //   perm: 'ops-menu-ota-publish-test',
        //   component: () => import('@/views/main/modules/ota/ota-qa-publish'),
        //   extra_routes: [
        //     {
        //       path: 'qa-publish/detail/:task_id',
        //       component: () => import('@/views/main/modules/ota/components/ota-task-base/task-item')
        //     }
        //   ]
        // },
        // {
        //   name: '正式发布',
        //   path: 'prod-publish',
        //   perm: 'ops-menu-ota-publish-prod',
        //   component: () => import('@/views/main/modules/ota/ota-prod-publish'),
        //   extra_routes: [
        //     {
        //       path: 'prod-publish/detail/:task_id',
        //       component: () => import('@/views/main/modules/ota/components/ota-task-base/task-item')
        //     }
        //   ]
        // }
      ]
    },
    {
      name: '平台管理',
      component: operation_center,
      path: '/platform',
      icon: 'monitor',
      perm: 'ops-menu-platform',
      children: [
        {
          name: '平台管理',
          path: 'platform',
          perm: 'ops-menu-platform',
          link: '/platform/platform',
          com_name: 'platform-platform',
          component: () => import('@/views/main/modules/operation-center/platform'),
          extra_routes: [
            {
              path: 'platform/created',
              component: () => import('@/views/main/modules/operation-center/platform/data-view/create')
            },
            {
              path: 'platform/data-view/:user_id',
              component: () => import('@/views/main/modules/operation-center/platform/data-view')
            }
          ]
        }
      ]
    },
    {
      name: '系统管理',
      component: system,
      path: '/system',
      icon: 'setting',
      children: [
        {
          name: '用户管理',
          path: 'user',
          perm: 'ops-menu-user',
          link: '/system/user',
          com_name: 'system-user-manager',
          component: () => import('@/views/main/modules/system/user-new'),
          // 如果存在不显示于navigator的页面路由，
          // 请添加在extra_routes中，会被全量读取到vue-router
          // 并添加与该子module相同的meta信息用来显示navigator高亮模块
          extra_routes: [
            {
              path: 'user/user-detail',
              component: () => import('@/views/main/modules/system/user-new/user-detail/user-detail.vue')
            }
          ]
        },
        {
          name: '角色管理',
          path: 'role',
          perm: 'ops-menu-role',
          link: '/system/role',
          com_name: 'system-role-manager',
          component: () => import('@/views/main/modules/system/role-new'),
          extra_routes: [
            {
              path: 'role/role-detail',
              component: () => import('@/views/main/modules/system/role-new/role-detail/role-detail.vue')
            }
          ]
        },
        {
          name: '版本管理',
          path: 'version',
          perm: 'ops-menu-version-management',
          link: '/system/version',
          com_name: 'system-version-manager',
          component: () => import('@/views/main/modules/system/version')
        },
        {
          name: '费率管理',
          path: 'rate',
          perm: 'ops-menu-charging-rule',
          link: '/system/rate',
          com_name: 'system-rate-manager',
          component: () => import('@/views/main/modules/system/rate'),
          extra_routes: [
            {
              path: 'rate/edit/:type',
              component: () => import('@/views/main/modules/system/rate/components/build')
            },
            {
              path: 'rate/data-view/:order_id',
              component: () => import('@/views/main/modules/system/rate/data-view/index')
            },
          ]

        }
      ]
    },
    {
      name: '产品管理',
      component: product,
      path: '/product',
      icon: 'menu',
      perm: 'ops-menu-product',
      children: [
        // {
        //   name: '电表管理',
        //   path: 'electric',
        //   perm: 'ops-menu-electric-management',
        //   component: () => import('@/views/main/modules/product/productlist/index'),
        //   extra_routes: [
        //     {
        //       path: 'electric/detail/:electric_id',
        //       component: () => import('@/views/main/modules/workflow-center/workflow/details/workflow-detail')
        //     }
        //   ]
        // },
        {
          name: '产品列表',
          path: 'product',
          perm: 'ops-menu-product',
          link: '/product/product',
          com_name: 'product-list',
          component: () => import('@/views/main/modules/product/product/index'),
          extra_routes: [
            {
              path: 'product/data-view/:product_id',
              component: () => import('@/views/main/modules/product/product/data-view/index')
            },
          ]
        }
      ]
    },
    {
      name: '设备管理',
      component: product,
      path: '/device',
      icon: 'cpu',
      perm: 'ops-menu-device-pool',
      children: [
        {
          name: '设备池',
          path: 'pile',
          perm: 'ops-menu-device-pool',
          link: '/device/pile',
          com_name: 'device-pile-pool',
          component: () => import('@/views/main/modules/product/pile/index'),
          extra_routes: [
            {
              path: 'pile/data-view/:pile_id',
              component: () => import('@/views/main/modules/product/pile/data-view/index')
            },
          ]
        }
      ]
    },
    {
      name: '数据报表',
      component: data_form,
      path: '/data_form',
      icon: 'trendCharts',
      children: [
        {
          name: '数据总览',
          path: 'data-screening',
          perm: 'ops-menu-data-screening',
          link: '/data_form/data-screening',
          com_name: 'data-screening',
          component: () => import('@/views/main/modules/data-form/data-screen/index')
        },
        {
          name: '站点排行',
          path: 'site-rank',
          perm: 'ops-menu-site-ranking',
          link: '/data_form/site-rank',
          com_name: 'site-rank',
          component: () => import('@/views/main/modules/data-form/site-rank/index')
        },
        {
          name: '站点占比',
          path: 'site-proportion',
          perm: 'ops-menu-site-proportion',
          link: '/data_form/site-proportion',
          com_name: 'site-proportion',
          component: () => import('@/views/main/modules/data-form/site-proportion/index')
        },
        {
          name: '订单分析',
          path: 'order-analysis',
          perm: 'ops-menu-order-analysis',
          link: '/data_form/order-analysis',
          com_name: 'order-analysis',
          component: () => import('@/views/main/modules/data-form/order-analysis/index')
        },
        {
          name: '月度分析',
          path: 'monthly-analysis',
          perm: 'ops-menu-monthly-analysis',
          link: '/data_form/monthly-analysis',
          com_name: 'monthly-analysis',
          component: () => import('@/views/main/modules/data-form/monthly-analysis/index')
        },
        {
          name: '城市排行',
          path: 'city-rank',
          perm: 'ops-menu-city-ranking',
          link: '/data_form/city-rank',
          com_name: 'city-rank',
          component: () => import('@/views/main/modules/data-form/city-rank/index')
        },
        {
          name: '枪用电分析',
          path: 'day-average',
          perm: 'ops-menu-gun-electricity-analysis',
          link: '/data_form/day-average',
          com_name: 'day-average',
          component: () => import('@/views/main/modules/data-form/day-average/index')
        },
        {
          name: '日报表',
          path: 'day-report',
          perm: 'ops-menu-daily-report',
          link: '/data_form/day-report',
          com_name: 'day-report',
          component: () => import('@/views/main/modules/data-form/daily-report/index')
        },
        {
          name: '设备使用情况',
          path: 'deviceUsage',
          perm: 'ops-menu-device-usage-situation',
          link: '/data_form/deviceUsage',
          com_name: 'device-usage',
          component: () => import('@/views/main/modules/data-form/deviceUsage/index')
        },
        {
          name: '报表导出',
          path: 'export',
          perm: 'ops-menu-report-export',
          link: '/data_form/export',
          com_name: 'report-export',
          component: () => import('@/views/main/modules/data-form/form-export/index')
        },
        {
          name: '电量统计',
          path: 'electricity-report',
          perm: 'ops-menu-report-electricity',
          link: '/data_form/electricity-report',
          com_name: 'electricity-report',
          component: () => import('@/views/main/modules/data-form/electricity-report/index')
        },
        {
          name: '充值提现统计',
          path: 'recharge-withdrawal',
          perm: 'ops-menu-recharge-and-withdrawal-statistics',
          link: '/data_form/recharge-withdrawal',
          com_name: 'recharge-withdrawal',
          component: () => import('@/views/main/modules/data-form/recharge-withdrawal/index')
        },
        {
          name: '未收款统计',
          path: 'unpaid-payments',
          perm: 'ops-menu-unpaid-payment-statistics',
          link: '/data_form/unpaid-payments',
          com_name: 'unpaid-payments',
          component: () => import('@/views/main/modules/data-form/unpaid-payments/index')
        },
        {
          name: '日历图',
          path: 'calendar-chart',
          perm: 'ops-menu-calendar-chart',
          link: '/data_form/calendar-chart',
          com_name: 'calendar-chart',
          component: () => import('@/views/main/modules/data-form/calendar-chart/index')
        },
        {
          name: '多商户报表',
          path: 'merchant-report',
          perm: 'ops-menu-unpaid-payment-statistics',
          link: '/data_form/merchant-report',
          com_name: 'merchant-report',
          component: () => import('@/views/main/modules/data-form/merchant-report/index')
        },
        {
          name: '场站用电情况',
          path: 'station-power',
          perm: 'ops-menu-unpaid-payment-statistics',
          link: '/data_form/station-power',
          com_name: 'station-power',
          component: () => import('@/views/main/modules/data-form/station-power/index')
        },
      ]
    }
  ]
};

import axios from 'axios';
// import store from '@/store';
import router from '@/router';
import { ElMessage } from 'element-plus';

// 在后端实现前（如 传-1 获取所有数据） 使用较大的数量模拟获取所有数据
export const SIZE_NUMBER_FOR_ALL = -1;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

export const Base_URL = '/api/v1';

const call = axios.create({
  baseURL: Base_URL,
  timeout: 30000,
  withCredentials: false
});

call.interceptors.request.use(config => {
  if (!config.headers) config.headers = {};
  config.headers = {
    'client-type': 'Operation'
  };
  if (config.full_path) {
    config.baseURL = null;
    if (config.file_upload) {
      config.headers.Accept = '*/*';
      config.headers['Content-Type'] = 'multipart/form-data';
    }
  } else {
    config.headers.Authorization = window.localStorage.getItem('access_token');
  }
  if (!config.no_auth && (!config.headers.Authorization || config.headers.Authorization === 'null')) {
    router.push('/login');
  }
  return config;
}, error => {
  console.log(error);
  Promise.reject(error);
});

call.interceptors.response.use(response => {
  const { status, data } = response;
  if (status === 200) {
    if (data.code === 401) {
      router.push('/login');
      window.location.href = '/login?error=401';
      ElMessage.warning('登录过期');
    }
    return data;
  } else if (status === 401) {
    window.location.href = '/login?error=401';
    ElMessage.warning('登录过期');
  } else {
    console.log('Error');
  }
}, error => {
  if (error && error.response && error.response.status === 401) {
    window.location.href = '/login?error=401';
    ElMessage.warning('登录过期');
  } else {
    throw error;
  }
});
export function down(url,params={}){
  return new Promise((resolve,reject) => {
    call.get(url,{
      responseType: 'arraybuffer',
      params:params
    })
      .then(response => {
        if (response.status==200) {
          resolve(response)
        }else{
          resolve(response)
        }
      })
      .catch(err => {
        reject(err);
        let message = '请求失败！请检查网络';
        //错误返回
        // if(err.response)message=err.response.data.message;
      })
  })
}

export default call;

import moment from 'moment';
import call from './call';

/*
  获取故障 top N
*/
export const get_top_n_station_faults = (periodType, periodValue, topN, agentId) => {
  let url = `/mgmt/analysis/device/topNStationFaults?periodType=${periodType}&periodValue=${periodValue}&topN=${topN}`;
  if (agentId) {
    url += `&agentIds=${agentId}`;
  }
  return call.get(url);
};

/*
  使用率 top N
*/
export const get_top_n_usage_rage = (periodType, periodValue, topN) => {
  const url = `/mgmt/analysis/device/topNUsageRateOfChargingPiles?periodType=${periodType}&periodValue=${periodValue}&topN=${topN}`;
  return call.get(url);
};

export const get_last_7_day_capacity_trend = () => {
  return call.get(`/mgmt/analysis/order/volumeAndCapacityTrend?periodType=day&latestPeriodValue=7`);
};

export const get_today_order_summary = () => {
  const period_value = moment().format('yyyy-MM-DD');
  return call.get(`/mgmt/analysis/order/orderSummary?periodType=day&periodValue=${period_value}`);
};

export const get_today_user_summary = () => {
  const period_value = moment().format('yyyy-MM-DD');
  return call.get(`/mgmt/analysis/user/summary?periodType=day&periodValue=${period_value}`);
};

export const get_user_region_summary = (regionCode) => {
  return call.get('/mgmt/analysis/region/user/stats', {
    params: { regionCode }
  });
};

export const get_today_region_summary = (regionCode) => {
  const periodValue = moment().format('yyyy-MM-DD');
  // /?periodType=day&periodValue=2022-09-18&regionCodes=330000
  return call.get('/mgmt/analysis/order/orderSummary', {
    params: {
      periodType: 'day',
      periodValue,
      regionCodes: regionCode
    }
  });
};

export const get_gz_top5_region_summary = (periodType, regionCode) => {
  const formate = periodType === 'month' ? 'yyyy-MM' : 'yyyy';
  const periodValue = moment().format(formate);
  return call.get('/mgmt/analysis/device/topNStationFaults', {
    params: {
      periodType,
      periodValue,
      topN: 5,
      regionCodes: regionCode
    }
  });
};

export const get_usage_top5_region_summary = (periodType, regionCode) => {
  const formate = periodType === 'month' ? 'yyyy-MM' : 'yyyy';
  const periodValue = moment().format(formate);
  return call.get('/mgmt/analysis/device/topNUsageRateOfChargingPiles', {
    params: {
      periodType,
      periodValue,
      topN: 5,
      regionCodes: regionCode
    }
  });
};

export const get_last_7_day_region_capacity_trend = (regionCode) => {
  return call.get('/mgmt/analysis/order/volumeAndCapacityTrend', {
    params: {
      periodType: 'day',
      latestPeriodValue: '7',
      regionCodes: regionCode
    }
  });
};

export const get_register_users_count = () => {
  return call.get('/mgmt/analysis/user/count');
};

export const get_piles_by_region = (regionCode) => {
  return call.get('/mgmt/analysis/region/pile/stats', {
    params: { regionCode }
  });
};

export const get_device_stats_by_region = (regionCode) => {
  const periodValue = moment().format('yyyy-MM-dd');
  return call.get('/mgmt/analysis/device/stats', {
    params: {
      periodType: 'day',
      regionCode,
      periodValue
    }
  });
};

export const get_user_charging_stats = async (regionCodes) => {
  const { data: week } = await call.get('/mgmt/analysis/currentWeek');
  return call.get('/mgmt/analysis/user/chargingUserStats', {
    params: {
      periodType: 'week',
      periodValue: week,
      regionCodes
    }
  });
};

export const get_charging_cout = (regionCodes, range) => {
  return call.get('/mgmt/analysis/order/volumeAndCapacityTrend', {
    params: {
      periodType: range === 'week' ? 'day' : 'month',
      latestPeriodValue: range === 'week' ? 7 : 12,
      regionCodes
    }
  });
};

// export const get_charging_cout = (regionCodes, range) => {
//   let periodType = '-';
//   let latestPeriodValue = 0;
//   if (range === 'week') {
//     periodType = 'day';
//     const week_start = moment().startOf('week');
//     latestPeriodValue = moment().diff(week_start, 'day') + 1;
//   } else {
//     periodType = 'year';
//   }
//   return call.get('/mgmt/analysis/order/volumeAndCapacityTrend', {
//     params: {
//       periodType,
//       latestPeriodValue,
//       regionCodes
//     }
//   });
// };

export const get_top_5_volumn_stations = (regionCodes, periodType) => {
  return call.get('/mgmt/analysis/order/topNVolumeStations', {
    params: {
      periodType,
      regionCodes,
      topN: 5,
      latestPeriodValue: 1
    }
  });
};
<template>
  <div class="fc-image-preview" v-if="visiable">
    <el-icon v-if="is_loading" class="is-loading" style="width: 4.4rem; height: 4.4rem;">
      <el-image :src="icon_loading" style="width: 4.4rem; height: 4.4rem;"></el-image>
    </el-icon>
    <el-image
      v-if="src"
      :src="src"
      style="width: 40rem; height: 40rem;"
      fit="contain"
    ></el-image>

    <el-icon @click="on_close_click_handler" class="fc-image-preview-close-btn"><closeBold /></el-icon>
  </div>
</template>
<script setup>
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import icon_loading from '@/assets/icons/common/loading.svg';

const visiable = ref(false);

const src = ref(null);
const is_loading = ref(false);
const open_with_src_promise = async (src_promise) => {
  try {
    src.value = null;
    visiable.value = true;
    is_loading.value = true;
    src.value = await src_promise();
  } catch (error) {
    console.error(error);
    ElMessage.error('获取图片信息失败');
  } finally {
    is_loading.value = false;
  }
};

const open_with_src = (src_value) => {
  console.log('src_value', src_value);
  src.value = null;
  visiable.value = true;
  is_loading.value = false;
  src.value = src_value;
};

const on_close_click_handler = () => {
  src.value = null;
  visiable.value = false;
  is_loading.value = false;
};

defineExpose({ open_with_src_promise, open_with_src });
</script>
<style lang="scss" scoped>
.fc-image-preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .fc-image-preview-close-btn {
    color: white;
    /* width: 12rem; */
    font-size: 2.8rem;
    position: absolute;
    right: 2.2rem;
    top: 2.2rem;
    cursor: pointer;
  }
}
</style>
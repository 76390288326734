<template>
  <div :class="`fc-dynamic-cell-table-container ${custom_class}`" v-loading="loading">
    <el-table
      :data="data"
      border
      stripe
      class="fc-dynamic-cell-table"
      header-cell-class-name="fc-dynamic-header-cell"
      :height="height"
      :header-cell-style="headerStyle"
      :empty-text="empty_text"
      max-height="550px"
      :default-expand-all="defaultExpendAll"
      :row-key="rowKey"
      @selection-change="on_selection_change_handler"
      @row-click="on_row_click_handler"
    >
      <el-table-column v-if="selectable" type="selection" width="55" />
      <el-table-column type="expand" v-if="expand">
        <template #default="props">
          <div class="table-column-expend">
            <div class="expend-item" v-for="item in expandData" :key="rowKey+item.value">
              {{item.label}}：{{item.Map?(item.Map[props.row?.[item.value]]||item.defaultData):(props.row?.[item.value]||item.defaultData)}}{{item?.unit||''}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col, index) in permed_column"
        :key="`column-${index}`"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :fixed="col.fixed"
        :align="col.align || 'center'"
        :min-width="col.min_width"
        :sortable="col.isSort || false"
        :filters = "col.isFilter ? objArr(col.prop, col.filterMap?col.filterMap:null) : null"
        :filter-method="col.isFilter?filterHandler:null"
      >
        <template #default="scope">
          <span v-if="col.isExpend">
            {{col.value_fn ? col.value_fn(scope.row) : get(scope.row, col.prop, '-')}}
          </span>
          <div
            class="static-cell"
            :class="col.class?col.class:''"
            v-else-if="!col.component"
          >
            {{col.value_fn ? col.value_fn(scope.row) : get(scope.row, col.prop, col.default_data||'-')||col.default_data||'-'}}
          </div>
          <component
            v-else
            :is="col.component"
            :row="scope.row"
            :class="col.class?col.class:''"
            :column="permed_column[index]"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="fc-pagination-wrapper" v-if="!hide_pagination">
      <el-pagination
        background
        v-model:currentPage="current"
        v-model:page-size="size"
        layout="sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="on_size_change_handler"
        @current-change="on_current_change_handler"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { get } from 'lodash';
import { useStore } from 'vuex';

const emit = defineEmits(['pagination-change', 'selection-change', 'row-click']);
const props = defineProps({
  // 表格load的数据
  data: { type: Array, default: () => [] },
  // 标记是否处于loading状态
  loading: { type: Boolean, default: false },
  defaultExpendAll: { type: Boolean, default: false},
  /* 列显示规则
    {
      prop:     string,     直接赋值 el-table-column 的 prop 属性，默认情况下 cell 会显示 row[prop]
      label:    string,     直接赋值 el-table-column 的 label 属性
      value_fn: function,   若 value_fn 有值，则 cell 会显示 value_fn(row) 的返回值
      component: object,
        - 若配置了component 则cell会以该组件进行渲染，以此来做到动态的cell渲染
        - 传入一个 vue component （该component 写到 ./cells 下，以 xxx.fc-cell.vue命名）
        - component 需要强制接收 row (scope.row) 和 column 2个属性， 模仿 date.fc-cell.vue
    }
  */
  columns: { type: Array, default: () => [] },
  // 数据为空时文本
  empty_text: { type: String, default: '无数据' },

  // table row 是否可选择
  selectable: { type: Boolean, default: true },
  custom_class: { type: String, default: () => '' },
  height: { type: String, default: () => null },
  // pagination
  hide_pagination: { type: Boolean, default: false },
  headerStyle:{ type: Object, default: null },
  rowKey: {type: String, default: 'id'},
  expand: {type: Boolean,default: false},
  expandData: {type: Array, default: () => []},// [{label:'应付金额',value:'money',unit:'元'}]
});

const store = useStore();
const button_permissions = computed(() => store.state.navigator.button_permissions);
// const permission_key_check_list = ['actions', 'perm'];
const permed_column = computed(() => {
  const result = [];
  // let filterArr = [];
  props.columns.forEach(col => {
    if (col.prop === '') {
      // debugger;
    }
    if (col.perm && col.perm.length > 0) {
      const found_index = button_permissions.value.findIndex(p => p.selector === col.perm);
      if (found_index > -1) {
        result.push(col);
      }
    } else if (col.actions && col.actions.length > 0) {
      const perms = col.actions.filter(a => a.perm).map(a => a.perm);
      if (perms && perms.length > 0) {
        const found_index = button_permissions.value.findIndex(p => perms.includes(p.selector));
        if (found_index > -1) {
          result.push(col);
        }
      } else {
        result.push(col);
      }
    } else {
      result.push(col);
    }
  });
  // console.log(store.getters.has_button_permission_at('ops-menu-advert-compnay-delete'));
  return result;
});

// Mark: - Pagination
const current = ref(1);
const size = ref(10);
const total = ref(1);

const on_size_change_handler = () => {
  console.log('111');
  emit('pagination-change', { current: current.value, size: size.value });
};

const on_current_change_handler = () => {
  console.log('222');
  emit('pagination-change', { current: current.value, size: size.value });
};

const on_selection_change_handler = (values) => {
  console.log('333');
  emit('selection-change', values);
};

const on_row_click_handler = (row) => {
  console.log('点击下载');
  console.log('row',row);
  // emit('row-click', row);
};
const filterHandler = (
  value,
  row,
  column
) => {
  const property = column.property;
  return row[property] === value;
};
const objArr = (prop, filterMap) => {
  let filterArr = [];
  let filterArrWithRepeat = [];
  if (prop.includes('.')) {
    filterArrWithRepeat = props.data.map((i) => {
      return i[prop.split('.')[0]] ? i[prop.split('.')[0]][prop.split('.')[1]] : null;
    });
  } else {
    filterArrWithRepeat = props.data.map((i) => {
      return i[prop];
    });
  }
  if (!filterMap) {
    filterArr = filterArrWithRepeat.filter((i, ind) => {
      return filterArrWithRepeat.indexOf(i) === ind;
    });
    filterArr = filterArr.map((i) => {
      return { text: i, value: i };
    });
  } else {
    filterArr = filterArrWithRepeat.filter((i, ind) => {
      return filterArrWithRepeat.indexOf(i) === ind;
    });
    filterArr = filterArr.map((i) => {
      return { text: filterMap[i], value: i };
    });
  }

  if (filterArr.length > 0) {
    return filterArr;
  } else {
    return null;
  }
};
// Mark: Expose
const get_pagination = () => {
  return { current: current.value, size: size.value, total: total.value };
};
const set_pagination = (t, c, s) => {
  if (t !== null && t !== undefined) total.value = t;
  if (c !== null && c !== undefined) current.value = c;
  if (s !== null && s !== undefined) size.value = s;
};

defineExpose({
  get_pagination,
  set_pagination
});

</script>
<style lang="scss">
.fc-dynamic-cell-table-container {

  .fc-dynamic-cell-table {
    .table-column-expend{
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      column-gap: 8rem;
      row-gap: 1rem;
      padding: 0 20px;
      .expend-item{
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #666;
        flex-shrink: 0;
      }
    }
    .fc-dynamic-header-cell {
      background-color: #ECEFF4;
      font-size: 1.4rem;
      font-weight: bold;
      color: var(--charging-pile-color-title);
    }
  }

  .fc-pagination-wrapper {
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    margin-top: .8rem;
    .el-pagination {
      padding-left: 0;
    }
  }
}
.jy-table-cell_primary {
  color: #3F93FF;
}
</style>

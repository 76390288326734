<template>
  <div :class="navigator_class">
    <logo />
    <menus />
    <div @click="on_collapse_handler" class="navigator-collapsed-button">
      <el-image v-if="!is_collapse" :src="require('@/assets/icons/actions/collapse.svg')" />
      <el-image v-else :src="require('@/assets/icons/actions/collapsed.svg')" />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import menus from './navigator-menus.component.vue';
import logo from './navigator-logo.component.vue';
import { navigator_types } from '@/store/navigator.store.js';

const store = useStore();

const navigator_class = computed(() => {
  return {
    'charging-pile-navigator': true,
    'is-collapsed': store.state.navigator.is_collapse,
    'is-mobile-open': store.state.navigator.is_mobile_menu_open
  };
});

const is_collapse = computed(() => store.state.navigator.is_collapse);

const on_collapse_handler = () => {
  store.commit(navigator_types.m_toggle_is_collapse.path);
};

</script>
<style lang="scss">
.charging-pile-navigator {
  height: 100%;
  width: var(--charging-pile-size-navigator-width);
  background-color: var(--charging-pile-color-primary);
  // background-color: var(--charging-pile-color-primary);
  // border-right: var(--charging-pile-split-border);
  transition: width .3s;
  &.is-collapsed {
    width: var(--charging-pile-size-navigator-collapsed-width);
    .navigator-logo {
      display: none;
    }
  }

  .navigator-collapsed-button {
    cursor: pointer;
    height: var(--charging-pile-size-navigator-collapse-button-height);
    border-top: var(--charging-pile-split-border);
    display: flex;
    align-items: center;
    justify-content: center;
    .el-image {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
}

</style>

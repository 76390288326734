
const regex_dictionary = {
  mobile_phone: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
  url: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  version: /^\d+(?:\.\d+){2}$/
};
// ^([0-9]\\d|[0-9])(\\.([0-9]\\d|\\d)){2}$

export const regex_keys = { 
  url: 'url',
  mobile_phone: 'mobile_phone',
  email: 'email',
  version: 'version'
};

export default {
  valid: (value, regex_key) => {
    if (!regex_dictionary[regex_key]) return false;
    return regex_dictionary[regex_key].test(value);
  }
};

<template>
  <div class="home-map home-card">
    <!-- title -->
    <div class="home-card-title home-table-item">
      <span>充电站地图分布</span>
      <!-- <div>{{selected_region_code}}</div> -->
      <el-button type="primary" @click="show_all_china" :disabled="selected_region_code === null">返回全国地图</el-button>
    </div>
    <!-- map -->
    <div ref="chartRef" class="home-chart-container" />

    <!-- 统计数据 -->
    <div class="sum-data-wrapper">
      <div class="sum-item color-item">
        <div class="sum-data-label" style="color: white;">充电桩总个数</div>
        <div class="sum-data-value" style="color: white;">{{summary_pile}}</div>
      </div>
      <div class="sum-item" style="width: 7rem; display: flex; flex-direction: column; justify-content: space-between;">
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #1BD495;">空闲</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.free || 0}}</div>
        </div>
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #1e8ad5;">空余</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.partial_free || 0}}</div>
        </div>
      </div>
      <div class="sum-item" style="width: 8rem; display: flex; flex-direction: column; justify-content: space-between;">
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #3F93FF;">充电中</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.in_use || 0}}</div>
        </div>
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="">升级中</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.upgrading || 0}}</div>
        </div>
      </div>
      <div class="sum-item" style="width: 7rem; display: flex; flex-direction: column; justify-content: space-between;">
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #B3B3B3;">离线</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.offline || 0}}</div>
        </div>
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #FF9D3E;">故障</div>
          <div class="sum-data-sub-value">{{summary_pile_stats.abnomal || 0}}</div>
        </div>
      </div>
    </div>
    <div class="sum-data-wrapper">
      <el-tooltip
        class="box-item"
        effect="dark"
        content="不重复统计同个用户创建的多个订单"
        placement="top"
      >
        <div class="sum-item color-item">
          <div class="sum-data-label" style="color: white;">订单用户数</div>
          <div class="sum-data-value" style="color: white;">{{summary_user}}</div>
        </div>
        <!-- <div class="icon-value-title">{{title}}</div> -->
      </el-tooltip>
      
      <div class="sum-item" style="width: 12rem; display: flex; flex-direction: column; justify-content: space-between;">
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #1BD495;">本周用户数</div>
          <div class="sum-data-sub-value">{{summary_user_use_in_this_week || 0}}</div>
        </div>
        <div class="sum-data-sub-wrapper">
          <div class="sum-data-sub-value" style="color: #1e8ad5;">使用中用户数</div>
          <div class="sum-data-sub-value">{{summary_user_in_progress || 0}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import * as echarts from 'echarts';
import a from "./dianwei01.webp";
import b from "./dianwei02.webp";
import "@/assets/map/china.js";
import "@/assets/map/chinaMapOutline.js";
import { useStore } from 'vuex';

const store = useStore();

const nameCode = require("@/assets/map/name_code.json");

// Mark: - Store Data
const summary_user = computed(() => store.state.home.summary_user_number);
const summary_pile = computed(() => store.state.home.summary_pile_number);
const summary_pile_stats = computed(() => store.state.home.summary_pile_stats);
const summary_user_use_in_this_week = computed(() => store.state.home.summary_user_use_in_this_week);
const summary_user_in_progress = computed(() => store.state.home.summary_user_in_progress);

const init_options = () => {
  return {
    geo: [
      // 中国地图
      {
        type: "map",
        map: "china",
        aspectScale: 0.75,
        center: [104.2, 35.9],
        zoom: 1.5,
        label: {
          show: false,
          // 默认文本标签样式
          // @ts-ignore
          normal: {
            color: "white",
            formatter: (params) => {
              if (params.value) {
                return `{${params.data.type}|${params.name}}\n{value|${params.value}}`;
              }
              return "";
            },
            rich: {
              back1: {
                padding: [-35, 0, 0, 0],
                color: "white",
                width: 50,
                height: 110,
                lineHeight: 14,
                backgroundColor: {
                  image: b
                }
              },
              back2: {
                padding: [-35, 0, 0, 0],
                color: "white",
                width: 50,
                height: 110,
                lineHeight: 14,
                backgroundColor: {
                  image: a
                }
              },
              value: {
                padding: [-20, 0, 0, 0]
              }
            },
            width: 50,
            height: 75,
            show: true
          },
          // 高亮文本标签样式
          emphasis: {
            show: true,
            color: "white",
            fontSize: 16,
            fontWeight: "bold"
          }
        },
        itemStyle: {
          // 默认区域样式
          // @ts-ignore
          normal: {
            // 区域背景透明
            areaColor: "rgba(193, 218, 253, 1)",
            borderColor: "white",
            borderWidth: 1
          },
          // 高亮区域样式
          emphasis: {
            // 高亮区域背景色
            areaColor: "#01ADF2"
          }
        },
        select: {
          disabled: true
        }
      }
    ],
    series: [

    ]
  };
};

const init_province_options = (regionCode) => {
  return {
    geo: [
      {
        type: "map",
        map: regionCode + "_line",
        // 不响应鼠标交互
        silent: true,
        // 调整以下3个配置项与页面地图重合
        aspectScale: 0.75, // 地图的长宽比
        zoom: 1, // 放大级别
        top: 80,
        itemStyle: {
          // @ts-ignore
          normal: {
            // 地图底色
            areaColor: "#09425D",
            // 高亮边缘+阴影样式
            borderWidth: 2,
            borderColor: "#00FFFF",
            shadowBlur: 1,
            shadowColor: "#066784",
            shadowOffsetX: 0,
            shadowOffsetY: 10,
            opacity: 0
          }
        }
      },
      // 中国地图
      {
        type: "map",
        map: regionCode,
        aspectScale: 0.75,
        zoom: 1,
        top: 80,
        label: {
          // 默认文本标签样式
          // @ts-ignore
          normal: {
            color: "white",
            show: true
          },
          // 高亮文本标签样式
          emphasis: {
            show: true,
            color: "white",
            fontSize: 16,
            fontWeight: "bold"
          }
        },
        itemStyle: {
          // 默认区域样式
          // @ts-ignore
          normal: {
            // 区域背景透明
            areaColor: "rgba(193, 218, 253, 1)",
            borderColor: "white",
            borderWidth: 1
          },
          // 高亮区域样式
          emphasis: {
            // 高亮区域背景色
            areaColor: "#01ADF2"
          }
        },
        select: {
          disabled: true
        }
      }
    ],
    series: [
      {
        name: '',
        type: 'scatter',
        coordinateSystem: 'geo',
        symbolSize: 0,
        label: {
          show: true,
          position: "top",
          // @ts-ignore
          normal: {
            color: "white",
            formatter: (params) => {
              if (params.value) {
                return `{back1|${params.data.regionName}}\n{value|${params.data.summary}}`;
              }
              return "";
            },
            rich: {
              back1: {
                padding: [-35, 0, 0, 0],
                align: 'center',
                color: "white",
                width: 50,
                height: 110,
                lineHeight: 14,
                backgroundColor: {
                  image: b
                }
              },
              back2: {
                padding: [-35, 0, 0, 0],
                align: 'center',
                color: "white",
                width: 50,
                height: 110,
                lineHeight: 14,
                backgroundColor: {
                  image: a
                }
              },
              value: {
                align: 'center',
                padding: [-20, 0, 0, 0]
              }
              // b: {
              //   backgroundColor: {
              //     image: "xxx/xxx.jpg",
              //   },
              //   height: 40,
              // },
              // x: {
              //   fontSize: 18,
              //   fontFamily: "Microsoft YaHei",
              //   borderColor: "#449933",
              //   borderRadius: 4,
              // },
            },
            width: 50,
            height: 75,
            show: true
          }
        },

        data: []
      }
    ]
  };
};

const show_province = (regionCode) => {
  if (!regionCode) return;
  // Mark: - get data
  chart.setOption(init_province_options(regionCode));
};
const show_all_china = () => {
  chart.setOption(init_options(), true);
  // set_data();
  selected_region_code.value = null;
  store.state.home.regionCode = null;
  store.dispatch('home/sync_home_data');
};

let chart = null;
const chartRef = ref();
const selected_region_code = ref(null);
const set_data = (data) => {
  if (!chartRef.value) return;
  if (!chart) {
    chart = echarts.init(chartRef.value);
  }
  chart.setOption(init_options(data));

  chart.on("click", (params) => {
    const { name, data, componentType } = params;
    let regionCode = null;
    if (componentType === 'geo') {
      regionCode = nameCode[name];
    } else if (componentType === 'series') {
      regionCode = String((data).regionCode).substr(0, 2) + '0000';
    }
    console.log('regionCode', regionCode);
    
    if (regionCode) {
      selected_region_code.value = regionCode;
      store.state.home.regionCode = regionCode;

      show_province(regionCode);
      store.dispatch('home/sync_home_data');
    }
  });
};
onMounted(() => set_data());

</script>]
<style lang="scss" scoped>
.home-map.home-card {
  .home-chart-container {
    margin-top: 10%;
    width: 100%;
    height: 80%;
  }
}
.sum-data-wrapper {
  position: absolute;
  bottom: 2rem;
  height: 7.2rem;

  box-shadow: var(--el-box-shadow-light);
  border: 1px solid #F0F3F6;
  border-radius: 8px;
  display: flex;

  .sum-item {
    width: 12rem;
    height: 100%;
    padding: 1.2rem;
    
    &:first-child {
      background-color: #3F93FF;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .sum-data-sub-wrapper {
      display: flex;

      .sum-data-sub-value {
        font-weight: 500;
      }

      .sum-data-sub-value + .sum-data-sub-value {
        margin-left: 1.2rem;
      }
    }
  }

  .sum-data-label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #666666;
  }

  .sum-data-value {
    margin-top: 1.2rem;
    font-weight: bold;
    color: #333333;
    font-size: 1.6rem;
  }
}
.sum-data-wrapper + .sum-data-wrapper {
  left:  40rem;
}
</style>
<template>
  <div v-if="visibleDialog">
    <el-dialog
        custom-class="template-dialog dialogLoading"
        v-model="visibleDialog"
        :width="props.width"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <template v-slot:title>
        <div class="my-header">
          <div class="left-line"></div>
          <slot name="titleText"></slot>
        </div>
      </template>
      <slot name="bodyBox"></slot>
      <template #footer>
        <slot name="footerBox"></slot>
      </template>
    </el-dialog>
  </div>

</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import {ElLoading} from "element-plus";

const props = defineProps({
  show: { type: Boolean, default: false },
  width: { type: String, default: '30%'},
  loading:{ type: Boolean, default: false },
});
const emits = defineEmits(['closeDialog']);
const visibleDialog=ref(false);
const handleClose=()=>{
  visibleDialog.value=false;
  emits('closeDialog')
}
let loading
watch(
    () => props.loading,
    (value) => {
      if(value){
        loading= ElLoading.service({
          target:'.dialogLoading',
          lock:true,
          background:'rgba(246, 246, 246, 0.7)'
        })
      }
      if(!value){
        loading.close();
      }
    }
);
onMounted(()=>{
  visibleDialog.value=props.show
})
</script>
<style lang="scss">
.template-dialog{
  .my-header{
    display: flex;
    font-size: 1.4rem;
    .left-line{
      width: 7px;
      border-radius: 3px;
      margin-right: 10px;
      height: 16px;
      transform: translateY(-1px);
      background-color: #409EFF;
    }
  }
}
</style>
<template>
  <div class="home-month-year-switch">
    <div @click="() => toggle_handler(Home_Scope.year)" :class="`m-m-switch-item ${data === Home_Scope.year ? 'is-active' : ''}`">年</div>
    <div @click="() => toggle_handler(Home_Scope.week)" :class="`m-m-switch-item ${data === Home_Scope.week ? 'is-active' : ''}`">周</div>
  </div>
</template>
<script setup>
import { Home_Scope } from '@/utils/const.util';

defineProps({
  data: { type: String, default: () => 'week' }
});

const emit = defineEmits(['change']);

const toggle_handler = (val) => {
  emit('change', val);
};

</script>
<style lang="scss">
.home-month-year-switch {
  display: flex;
  // position: absolute;
  // right: var(--home-space);
  // top: var(--home-space);

  .m-m-switch-item {
    width: 3.2rem;
    height: 2.4rem;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.is-active {
      background: #3F93FF;
      color: white;
    }
  }

  .m-m-switch-item + .m-m-switch-item {
    margin-left: .6rem;
  }
}
</style>
const state = {
  // agents: null,
  current_tree_node: null,
  current_agent_detail: null,
  current_station_detail: null,
  show_widthdrawal: false,
  current_station_fg_rule_workflow: null,
  show_tree: true,
  sync_agents_interval: null,
  all_first_level_agents_ids: []
};

const getters = {
};

const mutations = {
};

const actions = {
  // async fetch_agents_tree({ state }, success) {
  //   if (state.sync_agents_interval) {
  //     clearInterval(state.sync_agents_interval);
  //     state.sync_agents_interval = null;
  //   }
  //   // state.agents = null;
  //   const { data: trace_id } = await start_get_agents_tree();
  //   state.sync_agents_interval = setInterval(async () => {
  //     const { data: is_done } = await get_future_is_done(trace_id);
  //     console.log(is_done, trace_id);
  //     if (is_done) {
  //       const { data: tree } = await get_future_result(trace_id);
  //       clearInterval(state.sync_agents_interval);
  //       state.sync_agents_interval = null;
  //       console.log('tree', tree, success);
  //       if (success) {
  //         success(tree);
  //       }
  //     }
  //   }, 1000);
  // }
  // async fetch_agents_tree({ state }) {

  // }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

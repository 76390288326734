<template>
  <!-- 
    row: 2
    col: 4
    充电总金额
   -->
  <homeCardIconValue 
    :icon="green"
    titleTips="全时期注册人数"
    title="注册人数"
    :data="count"
    :m2m="data.m2m"
    :y2y="data.y2y"
  />
</template>
<script setup>
import homeCardIconValue from '../home-cards-template/icon-value.home-card-temp.vue';
import green from '@/assets/icons/home/green.png';
import { computed } from 'vue';
import { get } from 'lodash';
import { useStore } from 'vuex';

const store = useStore();

const data = {
  m2m: 0,
  y2y: 0
};

const count = computed(() => {
  return get(store.state.home, 'summary_register_user', 0);
});

</script>
import { createStore } from 'vuex';
import navigator from './navigator.store';
import operations from './operations.store';
import location from './location.store';
import auth from './auth.store';
import home from './home.store';
import { get_all_meta_data } from '@/api/meta.api.js';
import { get_all_roles, get_user_profile, get_resources_tree } from '@/api/auth.api.js';

import router from '@/router';


export default createStore({
  state: {
    page_status: {},
    meta_data: [],
    roles: [],
    profile: {},
    resources: null,
    resources_tree: null,
    imagePreviewRef: null,
    imagePreviewNewRef: null,
    videoPreviewRef: null,
    show_filters: true,
    tabList: [],//页面缓存列表
    activeTab: '',//当前活跃页面
  },
  getters: {
    meta_options: state => code => {
      const parent = state.meta_data.find(m => m.code === code);
      return parent ? parent.subNodes : [];
    },
    has_button_permission_at: state => selector => {
      return state.navigator.button_permissions.findIndex(p => p.selector === selector);
    }
  },
  mutations: {
    clear_resources(state) {
      state.resources = null;
      state.resources_tree = null;
    }
  },
  actions: {
    async init_tab_data({ state }) {
      if (window.sessionStorage.getItem('active_tab')) {
        state.activeTab = window.sessionStorage.getItem('active_tab')
      }
      if (window.sessionStorage.getItem('tab_list') && JSON.parse(window.sessionStorage.getItem('tab_list')).length) {
        state.tabList = JSON.parse(window.sessionStorage.getItem('tab_list'));
      }
    },
    async clear_tab_data({ state }) {
      window.sessionStorage.setItem('active_tab', '');
      window.sessionStorage.setItem('tab_list', '');
      state.tabList = [];
      state.activeTab = '';
    },
    async change_tab_data({ state }, data) {
      if (state.tabList.length > 0 && state.tabList.filter((item) => item.link === data.link)?.length) {
        state.activeTab = data.link;
        console.log('data.link', data.link);
        await router.push(data.link)
      } else {
        console.log('data.link', data.link);
        state.tabList.push(data);
        state.activeTab = data.link;
        await router.push(data.link)
      }
      window.sessionStorage.setItem('active_tab', state.activeTab);
      window.sessionStorage.setItem('tab_list', JSON.stringify(state.tabList));
    },
    async del_tab_data({ state }, data) {
      let newArr = []
      state.tabList.forEach((item) => {
        if (item.link !== data.link) {
          newArr.push(item)
        }
      })
      if (newArr.length === 0) {
        await router.push('/index')
      }
      if (newArr.length > 0 && newArr.filter((item) => item.link === state.activeTab)?.length) {
      } else {
        if (newArr.length > 0) {
          state.activeTab = newArr[newArr.length - 1].link
          await router.push(newArr[newArr.length - 1].link)
        } else {
          state.activeTab = ''
        }
      }
      state.tabList = newArr;
      window.sessionStorage.setItem('active_tab', state.activeTab);
      window.sessionStorage.setItem('tab_list', JSON.stringify(state.tabList));
    },
    async init_meta_data({ state }) {
      if (state.meta_data.length > 0) return;
      const { data } = await get_all_meta_data();
      state.meta_data = data;
      console.log('meta:', data);
    },
    async init_all_roles({ state }) {
      if (state.roles.length > 0) return;
      const { data } = await get_all_roles();
      state.roles = data;
    },
    async sync_user_profile({ state }) {
      const { data } = await get_user_profile();
      state.profile = data;
    },
    async sync_resources_if_null({ state }) {
      if (state.resources) return;
      const { data } = await get_resources_tree();
      const resources = [];
      const set_resource = (nodes) => {
        nodes.forEach(node => {
          resources.push({
            resourceId: node.resourceId,
            code: node.code,
            name: node.name,
            description: node.description,
            platformId: node.platformId,
            parentId: node.parentId,
            resourceType: node.resourceType,
            selector: node.selector,
            sort: node.sort
          });
          if (node.children && node.children.length > 0) {
            set_resource(node.children);
          }
        });
      };
      set_resource(data || []);

      console.log('all resources', data);
      state = Object.assign(state, { resources, resources_tree: data || [] });
    }
  },
  modules: {
    navigator,
    operations,
    location,
    auth,
    home
  }
});

import TMap from 'TMap';

export const location_types = {
  init_map_instance: { name: 'init_map_instance', path: 'location/init_map_instance' }
};

const state = {
  map_suggestion: null,
  user_city_location: null
};

const getters = {
};

const mutations = {
  [location_types.init_map_instance.name](state, city = '宁波') {
    if (state.map_suggestion) return;

    state.map_suggestion = new TMap.service.Suggestion({
      pageSize: 20, // 返回结果每页条目数
      region: city, // 限制城市范围
      regionFix: false, // 搜索无结果时是否固定在当前城市,
      policy: 1
    });

    if (state.user_city_location) return;
    const geocoder = new TMap.service.Geocoder();
    geocoder
      .getLocation({ address: city })
      .then(result => {
        state.user_city_location = result.result.location;
        // console.log(result.result.location);
      });
  }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

import {api_upload_file, get_oss_key, upload_file} from '@/api/file.api.js';
// import { get_perms_gui } from '@/api/auth.api.js';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const state = {
  access_token: null,
  oss_authorization: null,
  perm_guis: []
};

const getters = {
};

const mutations = {
  set_access_token(state, access_token) {
    state.access_token = access_token;
    window.localStorage.setItem('access_token', access_token);
  },
  clear_access_token(state) {
    state.access_token = null;
    window.localStorage.setItem('access_token', null);
  }
};

const actions = {
  async upload_file({ state }, file) {
    // 判断 oss_authorization 是否已获 并未过期 （webapp打开时间大于1天）
    const date = moment().format('YYYY-MM-DD');
    if (!state.oss_authorization || date !== state.oss_authorization.date) {
      const { data: authorization } = await get_oss_key();
      state.oss_authorization = { ...authorization, date };
    }
    const fileKey = `${state.oss_authorization.prefix}-${uuidv4()}-${file.name}`;
    await upload_file(state.oss_authorization, file, fileKey);
    return fileKey;
  },
  async upload_file_new({ state }, file){
    return await api_upload_file(file);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

<template>
  <!-- 
    row: 2
    col: 2
    今日累计营收
   -->
  <homeCardIconValue 
    :icon="blue"
    titleTips="通过订单统计"
    title="今日充电总次数 (次)"
    :data="orderCount"
    :m2m="data.m2m"
    :y2y="data.y2y"
  />
</template>
<script setup>
import homeCardIconValue from '../home-cards-template/icon-value.home-card-temp.vue';
import blue from '@/assets/icons/home/blue.png';
import { computed } from 'vue';
// import { get_today_order_summary } from '@/api/home.api';
import numeral from 'numeral';

import { get } from 'lodash';
import { useStore } from 'vuex';

const store = useStore();

const data = {
  m2m: 0,
  y2y: 0
};

const orderCount = computed(() => {
  const value = get(store.state.home, 'summary_today.orderCount', 0);
  return numeral(`${value}`).format('0,0');
});

</script>
<template>
  <!-- 
    row: 2
    col: 1
    今日累计营收
   -->
  <homeCardIconValue 
    :icon="red"
    titleTips="通过订单统计"
    title="今日累计营收 (元)"
    :data="volumeSummary"
    :m2m="data.m2m"
    :y2y="data.y2y"
  />
</template>
<script setup>
import homeCardIconValue from '../home-cards-template/icon-value.home-card-temp.vue';
import red from '@/assets/icons/home/red.png';
import { computed } from 'vue';
import { useStore } from 'vuex';
// import { onMounted, reactive } from 'vue';
// import { get_today_order_summary } from '@/api/home.api';
import numeral from 'numeral';

import { get } from 'lodash';

const store = useStore();

const data = {
  m2m: 0,
  y2y: 0
};

const volumeSummary = computed(() => {
  const value = get(store.state.home, 'summary_today.volumeSummary', 0);
  return numeral(`${value}`).format('0,0.00');
});

// const sync_data = async () => {
//   // data.data = '9,982,028';
//   // data.m2m = 10.2;
//   // data.y2y = -10.2;

//   try {
//     const { data: res } = await get_today_order_summary();
//     data.data = numeral(get(res, 'volumeSummary', 0)).format('0,0');
//   } catch (error) {
//     console.error(error);
//   }
// };

// onMounted(() => sync_data());

</script>
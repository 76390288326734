<template>
  <div :class="`charging-pile-module-index ${module_key}`">
    <router-view></router-view>
  </div>
</template>
<script setup>
import { use_main_redirect } from '@/hooks/use-main-redirect.js';

const module_key = 'finance';
use_main_redirect(module_key);

</script>

<template>
  <fcDialog
    ref="dialog"
    title="用户信息"
    :show="dialog_visible"
    :rules="{}"
    :width="530"
    :default_data=default_data
    @close="dialog_visible=false"
    @submit="submit_handler"
  >
    <template #form> 

      <el-row :gutter="12">
        <el-col :span="24">
          <el-form-item prop="" label="姓名:">
            <div class="content">{{profile.userName}}</div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="12">
        <el-col :span="24">
          <el-form-item prop="" label="所属经销商:">
            <div class="profile-box-container">
              <el-tag
                v-for="(agent, index) in profile.agents"
                :key="index"
              >{{agent.agentName}}</el-tag>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="12">
        <el-col :span="24">
          <el-form-item prop="" label="角色:">
            <div class="profile-box-container">
              <el-tag
                v-for="(role, index) in profile.roles"
                :key="index"
              >{{role.name}}</el-tag>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

    </template>
  </fcDialog>
</template>
<script setup>
import { ref, computed } from 'vue';
import fcDialog from '@/components/fc-dialog';
import { useStore } from 'vuex';

const emit = defineEmits(['success']);

// Mark: - dialog
const dialog = ref(null);
const dialog_visible = ref(false);

// Mark: - form
const default_data = ref({});

// Mark: - Methods
const open_with_data = (_data = {}) => {
  dialog_visible.value = true;
};

// Mark: Profile
const store = useStore();
const profile = computed(() => {
  return store.state.profile;
});

const submit_handler = async ({ callback }) => {
  emit('success');
  dialog_visible.value = false;  
  const result = true;
  callback(result);
};

defineExpose({ open_with_data });

</script>
<style lang="scss">
.profile-box-container {
  .el-tag + .el-tag {
    margin-left: 1.2rem;
  }
}
</style>
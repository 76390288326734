<template>
  <el-form-item
    class="fc-login-form-item"
    label=""
    :prop="prop"
  >
    <el-input
      class="fc-login-input"
      :class="{'white-fc-login-input':whiteClass}"
      :model-value="data"
      @input="on_data_input"
      :placeholder="placeholder"
      :disabled="isDisable"
      :type="type"
      :show-password="isPassword"
    >
      <template #prepend >
        <el-image :src="icon_src" fit="contain" class="login-input-append-icon" />
      </template>
      <template #append v-if="(!is_code)&&(!isPassword)&&loginType!==2">
        <el-button
          :disabled="isActive || data.length === 0"
          @click="on_sms_click_handler"
          :class="`sms-btn ${(!isActive && data.length > 0) ? 'is-active' : ''}`"
        >
          {{!isActive ? '获取登录验证' : `${60 - counter}s` }}
        </el-button>
      </template>
    </el-input>
  </el-form-item >
</template>
<script setup>
import { watch } from 'vue';
import { useInterval } from '@vueuse/core';

const emit = defineEmits(['data-change', 'sms']);
const props = defineProps({
  data: { type: String, default: () => null },
  placeholder: { type: String, default: () => '' },
  icon_src: { type: String, default: () => null },
  is_code: { type: Boolean, default: false },
  prop: { type: String, default: () => null },
  isPassword: {type: Boolean, default: false},
  type: { type: String, default: null},
  loginType: {type: Number,default: 1},
  isDisable: {type: Boolean, default: false},
  whiteClass: { type: Boolean, default: false},
});

const on_data_input = (val) => {
  if (props.is_code) {
    if (val === '' || (/^\d{1,}$/.test(val) && val.length <= 6)) {
      emit('data-change', val);
    }
  } else emit('data-change', val);
};

// Mark: - SMS
const sms_lock_seconds = 60;
const { counter, pause, isActive, resume } = useInterval(1000, { controls: true });
pause();
const on_sms_click_handler = () => {
  counter.value = 0;
  resume();
  emit('sms');
};
watch(
  counter,
  () => { if (counter.value > sms_lock_seconds) pause(); }
);

</script>
<style lang="scss">
.fc-login-form-item {
  --charging-pile-login-size-input-height: 4.4rem
}

.fc-login-form-item {

  .fc-login-input {
    width: 100%;
    background: #F7FAFF;
    border: 2px solid #EAEDF1;
    border-radius: calc(var(--charging-pile-login-size-input-height) / 2);
    display: flex;
    align-items: center;

    .el-input__inner {
      border: none;
      box-shadow: none;
      height: var(--charging-pile-login-size-input-height);
      background-color: transparent;
    }

    .el-input-group__append {
      padding-right: 12.2rem;
      box-shadow: none;
      background-color: transparent;
      border: none;
    }

    .el-input-group__prepend {
      box-shadow: none;
      border: none;
      margin-top: 3px;
      background-color: transparent;
    }

    .login-input-append-icon {
      width: 2rem;
      height: 2rem;
    }

    .sms-btn {
      height: 3.2rem;
      width: 11rem;
      display: flex;
      align-items: center;
      background: #EAEDF1;
      border-radius: 1.6rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #9AA0A8;
      box-sizing: border-box;

      &:hover {
        background: #EAEDF1;
      }

      &.is-active {
        // background: var(--charging-pile-color-primary);
        background-color: #dbe6a4;
        color: black;
      }
    }

    // number styles

  }
  .white-fc-login-input{
    background:  #fff;
  }
}
</style>

import app_config from '@/config/app.config.js';

/*
  store modules 编写规范：
  1. 导出 module_name_types
    - mutation type 命名使用 m_ 开头
    - action type 命名使用 a_ 开头
    - getter type 命名使用 g_ 开头
    - 通过 { name: xxx, path: xxx } 进行包装，避免在调用时输入字符串
  sample:
    export const navigator_types = {
      m_xxx_xxx: { name: 'm_xxx_xxx', path: 'module_name/m_xxx_xxx'},
      a_xxx_xxx: { name: 'a_xxx_xxx', path: 'module_name/a_xxx_xxx'}
    };

  2. mutations sample:
    [module_name_types.m_xxx_xxx.name](state, payload) { ... }
  3. actions sample:
    [module_name_types.a_xxx_xxx.name]({ state, rootState }, payload) { ... }
*/

export const navigator_types = {
  g_has_permission_at: { name: 'g_has_permission_at', path: 'navigator/has_permission_at' },
  m_update_permissions: { name: 'm_update_permissions', path: 'navigator/m_update_permissions' },
  m_toggle_is_collapse: { name: 'm_toggle_is_collapse', path: 'navigator/m_toggle_is_collapse' },
  m_toggle_is_mobile_menu_open: { name: 'm_toggle_is_mobile_menu_open', path: 'navigator/m_toggle_is_mobile_menu_open' }
};

const state = {
  permissions: null,
  menu_permissions: [],
  button_permissions: [],
  visible_menus: [],
  is_collapse: false,
  is_mobile_menu_open: false
};

const getters = {
  // TODO: - 接入页面权限控制
  [navigator_types.g_has_permission_at.name]: state => (permission) => { return true; }
};

const mutations = {
  clearPermissions(state) {
    state.permissions = null;
    state.menu_permissions = [];
    state.button_permissions = [];
    state.visible_menus = [];
  },
  [navigator_types.m_update_permissions.name](state, permissions) {
    state.permissions = permissions;
    state.menu_permissions = permissions.filter(p => p.resourceType === 'MENU');
    state.button_permissions = permissions.filter(p => p.resourceType === 'BUTTON');
    // TODO: - 接入权限控制后通过permissions结果过滤当前用户可见菜单
    const _visible_menus = [];
    app_config.modules.forEach(module => {
      if (module.perm) {
        const found_index = state.menu_permissions.findIndex(p => p.selector === module.perm);
        if (found_index > -1) {
          _visible_menus.push({ ...module });
        }
      } else {
        const _module = { ...module };
        _module.children = [];
        (module.children || []).forEach(child => {
          const found_index = state.menu_permissions.findIndex(p => p.selector === child.perm || child.perm === 'pass');
          if (found_index > -1) _module.children.push({ ...child });
        });
        if (_module.children.length > 0) _visible_menus.push({ ..._module });
      }
    });
    console.log('_visible_menus', _visible_menus);
    state.visible_menus = _visible_menus;
  },
  [navigator_types.m_toggle_is_collapse.name](state) {
    state.is_collapse = !state.is_collapse;
  },
  [navigator_types.m_toggle_is_mobile_menu_open.name](state) {
    state.is_mobile_menu_open = !state.is_mobile_menu_open;
  }
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

<template>
  <div class="charging-pile-cabin">
    数据座舱（大屏展示页面）
    TODO: - 页面内容确认
  </div>
</template>
<script setup>

</script>
<style lang="scss" scoped>
.charging-pile-cabin {
  margin-top: 5%;
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
}
</style>
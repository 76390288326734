<template>
  <div :class="navigator_menus_class">
    <el-menu
      class="charging-pile-navigator-menu"
      :default-active="default_active"
      :collapse="is_collapse"
      ref="naviMenuRef"
    >
      <!-- visible menus loop -->
      <template
        v-for="(m, m_index) in visible_menus"
        :key="`m-${m_index}`"
      >
        <el-sub-menu
          v-if="m.children"
          :index="m.path"
        >
          <template #title>
            <el-icon><component :is="m.icon" /></el-icon>
            <div v-if="!is_collapse">{{m.name}}</div>
          </template>
          <el-menu-item
            v-for="(sub_m, sb_index) in m.children"
            :key="`sb-${sb_index}`"
            :index="`${m.path}/${sub_m.path}`"
            @click="(event) => on_click_item_handler(event, m, sub_m)"
          >
            <template #title>
              <div>{{sub_m.name}}</div>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item
          v-else
          :index="m.path"
          @click="() => on_parent_click_handler(m)"
        >
          <el-icon><component :is="m.icon" /></el-icon>
          <template #title>
            <div>{{m.name}}</div>
          </template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import { navigator_types } from '@/store/navigator.store.js';

// Mark: - Common
const env = inject('env');
const route = useRoute();
const store = useStore();
const router = useRouter();
const is_collapse = computed(() => store.state.navigator.is_collapse);
const navigator_menus_class = computed(() => {
  return {
    'navigator-menus': true,
    'is-collapsed': is_collapse.value
  };
});

const naviMenuRef = ref();
const on_click_item_handler = (event, m, sub_m) => {
  if(sub_m.link){
    store.dispatch('change_tab_data',sub_m)
  }else{
    if (sub_m.open_tab) {
      router.replace(route.fullPath);
      window.open(`${env.VUE_APP_URL}/${sub_m.path}`);
      window.history.go();
    } else {
      router.push(`${m.path}/${sub_m.path}`);
    }
  }
};

const on_parent_click_handler = (m) => {
  if(m.link){
    store.dispatch('change_tab_data',m)
  }else {
    if (m.path.startsWith('https')) {
      window.location.href = m.path;
    } else {
      console.log('sub', m)
      router.push(`${m.path}`);
    }
  }
};

// Mark: - Menu attributes
const default_active = ref(null);
const visible_menus = computed(() => store.state.navigator.visible_menus);

onMounted(() => {
  const { navigator_index } = route.meta;
  default_active.value = navigator_index;
});

</script>
<style lang="scss" scoped>
.navigator-menus {
  --charging-pile-size-navigator-menu-item-height: 3.8rem;

  --charging-pile-color-navigator-active-color: white;
  --charging-pile-color-navigator-unactive-color: #ffffff99;
}
.navigator-menus::-webkit-scrollbar { width: 0 !important }

.navigator-menus {
  // padding-top: 1.2rem;
  overflow-y: scroll;
  height: calc(100% - var(--charging-pile-size-navigator-collapse-button-height) - var(--charging-pile-navigator-logo-height));
  &.is-collapsed {
    height: calc(100% - var(--charging-pile-size-navigator-collapse-button-height));
  }
  // height: calc(100% - var(--charging-pile-size-profile-wrapper-height) - var(--charging-pile-size-logo-wrapper-height));
  ::v-deep(.charging-pile-navigator-menu) {
    border: none;
    background-color: transparent;
    .el-menu {
      background-color: transparent;
    }
    .el-menu-item {
      padding-left: 2.0rem !important;
      color: var(--charging-pile-color-navigator-unactive-color);
      height: var(--charging-pile-size-navigator-menu-item-height);
      &.is-active {
        // color: var(--charging-pile-color-primary);
        // border-left: .4rem solid var(--charging-pile-color-primary);
        border-left: .4rem solid var(--charging-pile-color-navigator-active-color);
        color: var(--charging-pile-color-navigator-active-color);
        padding-left: 1.6rem !important;
        // background-color: var(--el-color-primary-light-9);
      }
      &:hover {
        color: var(--charging-pile-color-primary);
      }
    }
    .el-sub-menu {
      .el-sub-menu__title {
        height: var(--charging-pile-size-navigator-menu-item-height);
        color: #ffffff99;
        &:hover {
          color: var(--charging-pile-color-primary);
        }
        .navigator-icon {
          height: 100%;
          display: flex;
          padding: .8rem 0;
          margin-right: .8rem;
        }
      }

      .el-menu-item {
        padding-left: 4.8rem !important;
        &.is-active {
          border-left: .4rem solid var(--charging-pile-color-navigator-active-color);
          padding-left: 4.4rem !important;
          color: var(--charging-pile-color-navigator-active-color);
          // background-color: var(--el-color-primary-light-9);
        }
        &:hover {
          color: var(--charging-pile-color-primary);
        }
      }
    }
  }
  ::v-deep(.charging-pile-navigator-menu.el-menu--collapse) {
    .el-tooltip__trigger {
      display: flex !important;
      align-items: center;
    }
    .el-sub-menu.is-active {
      border-left: .4rem solid var(--charging-pile-color-navigator-active-color);
      background-color: var(--el-color-primary-light-9);
      .el-sub-menu__title {
        padding-left: 1.6rem !important;
        color: var(--charging-pile-color-primary);
      }
      .el-sub-menu__title {
        color: var(--charging-pile-color-primary);
      }
    }
    .el-menu-item {
      &:hover {
        color: var(--charging-pile-color-primary);
      }
    }
  }
  .navigator-menus-white {
    display: none;
  }

}
</style>

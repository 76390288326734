/* eslint-disable */
(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    define(["exports", "echarts"], factory);
  } else if (
    typeof exports === "object" &&
    typeof exports.nodeName !== "string"
  ) {
    // CommonJS
    factory(exports, require("echarts"));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
})(this, function (exports, echarts) {
  const log = function (msg) {
    if (typeof console !== "undefined") {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log("ECharts is not Loaded");
    return;
  }
  if (!echarts.registerMap) {
    log("ECharts Map is not loaded");
    return;
  }
  const china = require("./china.json");
  echarts.registerMap("china", china);
  const files = require.context("./json", false, /\.json$/);
  const modules = {};
  files.keys().forEach((key) => {
    modules[key.replace(/(\.\/|\.json)/g, "")] = files(key);
  });
  Object.keys(modules).forEach((key) => {
    echarts.registerMap(key, modules[key]);
  });
});

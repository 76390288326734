import { 
  get_gz_top5_region_summary, 
  // get_last_7_day_region_capacity_trend, 
  get_device_stats_by_region, 
  get_register_users_count, 
  get_today_region_summary, 
  get_usage_top5_region_summary, 
  get_user_charging_stats,
  get_charging_cout,
  get_top_5_volumn_stations
} from "@/api/home.api";
import { ElMessage } from "element-plus";
import { get } from "lodash";
import numeral from 'numeral';

const state = {
  is_loading: false,
  regionCode: null,
  summary_user_number: 0,
  summary_user_use_in_this_week: 0,
  summary_user_in_progress: 0,
  summary_pile_number: 0,
  summary_pile_stats: {
    free: 0,
    partial_free: 0,
    in_use: 0,
    offline: 0,
    abnormal: 0,
    upgrading: 0
  },
  summary_today: {
    capacitySummary: 0,
    orderCount: 0,
    volumeSummary: 0
  },
  summary_today_register: 0,
  top_5_fault: [],
  top_5_usage: [],
  top_5_fault_period: 'month',
  top_5_usage_period: 'month',
  charging_capacity_trend: [],
  charging_capacity_trend_period: 'week',
  summary_register_user: 0,
  charging_cout_period: 'week',
  charging_cout_data: [],
  top_5_volumn_stations_data: [],
  top_5_volumn_stations_peroid: 'month'
};

const getters = {
};

const mutations = {
};

const actions = {
  async sync_home_data({ state, dispatch }) {
    try {
      state.is_loading = true;
      const [
        user_response,
        today_response,
        // last_7_capacity_response,
        register_response,
        pile_response
      ] = await Promise.all([
        get_user_charging_stats(state.regionCode),
        get_today_region_summary(state.regionCode),
        // get_last_7_day_region_capacity_trend(state.regionCode),
        get_register_users_count(),
        get_device_stats_by_region(state.regionCode)
      ]);
      // region 订单用户数量统计
      state.summary_user_in_progress = get(user_response, 'data.inProgressUsers', 0);
      state.summary_user_number = get(user_response, 'data.totalUsers', 0);
      state.summary_user_use_in_this_week = get(user_response, 'data.periodUsers', 0);
      // region 充电桩数量统计
      // state.summary_pile_number = (pile_response.data || []).map(p => p.summary).reduce((partialSum, a) => partialSum + a, 0);
      state.summary_pile_number = get(pile_response, 'data.chargingPileSummary.count', 0);
      const _summary_pile_stats = {};
      (get(pile_response, 'data.chargingPileSummary.stateDistribute', [])).forEach(distribute => {
        _summary_pile_stats[distribute.groupKey] = distribute.count;
      });
      state.summary_pile_stats = _summary_pile_stats;
      // region 今日 充电/订单/收入 统计
      state.summary_today = {
        capacitySummary: get(today_response, 'data.capacitySummary', 0),
        orderCount: get(today_response, 'data.orderCount', 0),
        volumeSummary: get(today_response, 'data.volumeSummary', 0)
      };
      // region 注册用户统计
      state.summary_register_user = get(register_response, 'data', 0);
      // region 过去7日充电走势
      // state.charging_capacity_trend = get(last_7_capacity_response, 'data', []).map(r => r.capacitySummary || 0);
      // region 故障率 top 5
      dispatch('sync_region_top_5_fault');
      // region 使用率 top 5
      dispatch('sync_region_top_5_usage');
      // region 充电次数
      dispatch('sync_region_charging_cout');
      // region 充电电量
      dispatch('sync_charging_capacity_trend');
      // region Top 5 营收
      dispatch('sync_volumn_stations');
    } catch (error) {
      console.error(error);
      ElMessage.error('获取首页数据出错');
    } finally {
      state.is_loading = false;
    }
  },
  async sync_region_top_5_fault({ state }) {
    const { data } = await get_gz_top5_region_summary(state.top_5_fault_period, state.regionCode);
    // region 故障 Top 5 统计
    state.top_5_fault = get(data, 'stateDistribute', []).map(d => {
      const value = numeral(`${d.percent || 0}`).format('0.00');
      return { content: d.groupKey, value: value + '%' };
    });
  },
  async sync_region_top_5_usage({ state }) {
    const { data } = await get_usage_top5_region_summary(state.top_5_usage_period, state.regionCode);
    // region 使用 Top 5 统计
    state.top_5_usage = get(data, 'topRecords', []).map(d => {
      const value = numeral(`${d.usageRate || 0}`).format('0.00');
      return { content: d.stationName, value: value + '%' };
    });
  },
  async sync_region_charging_cout({ state }) {
    const { data } = await get_charging_cout(state.regionCode, state.charging_cout_period);
    state.charging_cout_data = (data || []).map(d => d.orderCount);
  },
  async sync_charging_capacity_trend({ state }) {
    const { data } = await get_charging_cout(state.regionCode, state.charging_capacity_trend_period);
    state.charging_capacity_trend = (data || []).map(d => d.capacitySummary);
  },
  async sync_volumn_stations({ state }) {
    const { data } = await get_top_5_volumn_stations(state.regionCode, state.top_5_volumn_stations_peroid);
    // region 使用 Top 5 统计
    state.top_5_volumn_stations_data = (data || []).map(d => {
      const value = numeral(`${d.volume || 0}`).format('0.00');
      return { content: d.stationName, value };
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

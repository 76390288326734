<template>
  <el-popover
    ref="pop_ele"
    :width="120"
    popper-class="fc-popover-selector"
    trigger="click"
    placement="bottom-end"
  >
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <div 
      v-for="(option, index) in options"
      :key="`option-${index}`"
      :class="`fc-popover-selector-option ${index === 0 ? 'is-first' : ''}`"
      @click="() => on_option_click_handler(option.key)"
    >
      {{option.label}}
    </div>
  </el-popover>
</template>
<script setup>
import { ref } from 'vue';

defineProps({
  options: { type: Array, default: () => [] }
});
const emit = defineEmits(['option-click']);
const pop_ele = ref(null);

const on_option_click_handler = (k) => {
  pop_ele.value.hide();
  emit('option-click', k);
};

</script>
<style lang="scss">
.fc-popover-selector {
  min-width: 12rem !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  border-radius: none !important;

  .el-popper__arrow {
    display: none;
  }

  .fc-popover-selector-option {
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.8rem;
    cursor: pointer;
    border-top: var(--charging-pile-split-border);
    font-size: 1.3rem;
    font-weight: 500;
    &.is-first {
      border-top: none;
    }
    &:hover {
      background-color: var(--charging-pile-color-primary);
      color: white;
    }
  }
}
</style>
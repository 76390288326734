<template>
  <div class="charging-pile-header">
<!--    <headerLogo />-->
    <el-scrollbar style="padding-top: .3rem">
    <div class="tab-list">
      <div v-for="tab in tabList" :key="tab.link"
           @click="handleChangeTab(tab)"
           :class="{'tab-item-active':activeTab===tab.link,'tab-item':true}">
        <div class="tab-name">
          {{tab.name}}
        </div>
        <div @click.stop="handleDel(tab)">
          <el-icon style="font-size: 16px;vertical-align: middle"><closeBold /></el-icon>
        </div>
      </div>
    </div>
    </el-scrollbar>
    <headerProfile @messages-click="on_messages_click_handler" />
  </div>
  <messages ref="messages_ele" />
</template>
<script setup>
import headerLogo from './header-logo.component';
import headerProfile from './header-profile.component';
import messages from '../messages';
import {computed, ref} from 'vue';
import {useStore} from "vuex";

const store = useStore()
const messages_ele = ref(null);
const tabList = computed(() => store.state.tabList);
const activeTab = computed(() => store.state.activeTab);
const handleChangeTab=(tab)=>{
  store.dispatch('change_tab_data',tab)
}
const handleDel=(tab)=>{
  store.dispatch('del_tab_data',tab)
}
const on_messages_click_handler = () => {
  messages_ele.value.open();
};

</script>
<style lang="scss" scoped>
.charging-pile-header {
  height: var(--charging-pile-size-header-height);
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
  // background-color: var(--charging-pile-dark-background-color);
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  .tab-list{
    width: auto;
    flex-grow: 1;
    height: 4rem;
    //overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    .tab-item{
      height: 4rem;
      width: auto;
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      padding: 0 1rem 0 2rem;
      align-items: center;
      column-gap: .4rem;
      color: #666;
      cursor: pointer;
    }
    .tab-item-active{
      background-color: #3F93FF;
      color: #fff;
    }
  }
  .header-logo {
    display: none;
  }
}
</style>

<template>
  <div class="header-profile full">
    <!-- <el-badge @click="on_messages_click_handler" v-if="badge_value > 0" :value="badge_value" class="profile-message">
      <div class="profile-message-text">信息</div>
    </el-badge>
    <div @click="on_messages_click_handler" v-else class="profile-message-text">信息</div> -->
    <div class="profile-split"></div>
    <fcPopoverSelector
      :options="popover_options"
      @option-click="on_action_click_handler"
    >
      <template #reference>
        <div class="profile-avatar-name">
          <el-avatar size="small">{{profile.userName ? profile.userName[0] : '-'}}</el-avatar>
          <span >{{profile.userName || '-'}}</span>
        </div>
      </template>
    </fcPopoverSelector>
  </div>
  <div class="header-profile mobile" @click="on_mobile_menu_toggle">
    <el-image v-if="is_mobile_menu_open" :src="require('@/assets/icons/actions/collapse-dark.svg')" />
    <el-image v-else :src="require('@/assets/icons/actions/collapsed-dark.svg')" />
  </div>
  <profileDialog ref="profileDialogRef" />
  <changePwdDialog ref="changePwdRef"/>
</template>
<script setup>
import fcPopoverSelector from '@/components/fc-popover-selector';
import { computed, ref } from 'vue';
import { navigator_types } from '@/store/navigator.store.js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import profileDialog from './user-profile-dialog.vue';
import changePwdDialog from './change-pwd.vue'
// import { fc_message, fc_level } from '@/utils/ux.util.js';

defineEmits(['messages-click']);

// Mark: - mobile
const is_mobile_menu_open = computed(() => store.state.navigator.is_mobile_menu_open);
const on_mobile_menu_toggle = () => {
  store.commit(navigator_types.m_toggle_is_mobile_menu_open.path);
};
// Mark: - message
// const badge_value = ref(3);
// const on_messages_click_handler = () => emit('messages-click');
// Mark: - avatar

// Mark: - name
const popover_options = [
  { key: 'info', label: '用户信息' },
  { key: 'pwd', label: '修改密码' },
  { key: 'logout', label: '登出' }
];
const router = useRouter();
const profileDialogRef = ref();
const changePwdRef=ref();
const on_action_click_handler = (key) => {
  console.log('key', key);
  if (key === 'logout') {
    store.commit('clear_resources');
    store.commit('navigator/clearPermissions');
    store.commit('auth/clear_access_token');
    store.dispatch('clear_tab_data');
    router.replace('/login');
  } else if(key==='pwd'){
    changePwdRef.value.open_with_data();
  } else {
    // console.log(1);
    profileDialogRef.value.open_with_data();
  }
};
// Mark: Profile
const store = useStore();
const profile = computed(() => {
  return store.state.profile;
});

</script>
<style lang="scss" scoped>
.header-profile.full {
  // color: white;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .profile-avatar-name {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    cursor: pointer;
    .el-avatar {
      margin-right: .8rem;
    }
  }
  .profile-split {
    background-color: var(--charging-pile-color-split);
    width: 1px;
    height: 50%;
  }
  .profile-message-text {
    cursor: pointer;
    margin-right: 1.2rem;
  }
  .el-badge.profile-message {
    margin-right: 1.6rem;
    .profile-message-text {
      margin-right: .8rem;
    }
  }
}
.header-profile.mobile {
  display: none;
}
</style>

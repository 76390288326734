import call, { SIZE_NUMBER_FOR_ALL } from './call';

const service = '/auth';

export const sms = (phoneNumber) => {
  return call.post(`${service}/sms`, { phoneNumber });
};

export const login = (phoneNumber, verifyCode) => {
  return call.post(`${service}/login`, { phoneNumber, verifyCode });
};
/**
 * 密码登录
 * @param clientType
 * @param pwd
 * @param userName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login_by_pwd = ({clientType, pwd,userName}) => {
  return call.post(`${service}/auth/loginByPwd`, { clientType, pwd,userName });
};
/**
 * 修改新密码
 * @param password
 * @param verificationCode
 * @param phoneNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const reset_psd=({password,verificationCode,phoneNumber})=>{
  return call.post(`/auth/auth/clientResetPwd?password=${password}&verificationCode=${verificationCode}&phoneNumber=${phoneNumber}`,{password,verificationCode,phoneNumber})
}
/** User */
export const get_users = ({ pageSize, pageNo, keyword, isEndUserOnly }) => {
  let path = `${service}/users?isAsc=false&pageNo=${pageNo}&pageSize=${pageSize}&isEndUserOnly=${isEndUserOnly}&sort=updated_time&userId=-1`;
  if (keyword) {
    path += `&keyword=${keyword}`;
  }
  return call.get(path);
};

export const add_user = (user) => {
  return call.post(`${service}/users`, user);
  // if (res.code !== 200) return null;
  // const userId = res.data.userId;
  // await call.post(`/users/${userId}/roles`, (user.roleIds || []).map(id => {
  //   return { roleId: id };
  // }));
  // console.log(res);
};

export const get_user_detail = (user_id) => {
  return call.get(`/order/statistics/user/${user_id}`);
};

export const update_user = (user) => {
  return call.put(`${service}/users/${user.userId}`, user);
};

export const search_user_by_keyword = async (keyword) => {
  return call.get(`${service}/users?isAsc=true&pageNo=1&pageSize=${SIZE_NUMBER_FOR_ALL}&sort=updated_time&keyword=${keyword}`);
};

export const delete_user = (userId) => {
  return call.delete(`${service}/users/${userId}`);
};

export const get_user_profile = () => {
  return call.get(`${service}/users/profile`);
};

export const get_agent_users = async (agent_id) => {
  return call.get(`${service}/agents/${agent_id}/users`);
};

/** Role */
export const get_roles = ({ pageSize, pageNo, keyword }) => {
  let path = `${service}/roles?isAsc=true&pageNo=${pageNo}&pageSize=${pageSize}&sort=updated_time`;
  if (keyword) {
    path += `&keyword=${keyword}`;
  }
  return call.get(path);
};

export const get_all_roles = () => {
  return call.get(`${service}/roles?isAsc=true&pageNo=1&pageSize=-1&sort=created_time`);
};

export const add_role = (role) => {
  return call.post(`${service}/roles`, role);
};

export const put_role = (role) => {
  return call.put(`${service}/roles/${role.roleId}`, role);
};

export const delete_role = (roleId) => {
  return call.delete(`${service}/roles/${roleId}`);
};

export const get_role_detail = async (roleId) => {
  const promises = [
    call.get(`${service}/roles/${roleId}`),
    call.get(`${service}/resources/role/${roleId}`)
  ];
  const [res_role, res_resouces] = await Promise.all(promises);
  const role = res_role.data || {};
  const resources = res_resouces.data || [];
  return { ...role, resources };
};

export const update_role_resouces = (role_id, resource_ids) => {
  return call.post(`${service}/resources/role/${role_id}`, resource_ids || []);
};

/** Permissions */
export const get_resources_tree = () => {
  return call.get(`${service}/resources/tree?isAsc=true&orderBy=updated_time`);
};

export const get_perms_gui = () => {
  return call.get('/auth/perms/gui');
};

export const get_users_by_agent_and_role = (agentId, roleCode) => {
  return call.get(`/auth/users/agents/${agentId}?roleCodes=${roleCode}`);
};

/**
 * 版本管理列表获取
 * @param pageNo
 * @param pageSize
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiGetVersionList = ({ pageNo, pageSize }) => {
  return call.get('/mgmt/version_management/queryAll', { params: { pageNo, pageSize } });
};
/**
 * 新增版本
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiAddVersion = (data) => {
  return call.post('/mgmt/version_management/addVersion', { ...data });
};
/**
 * 修改版本
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiEditVersion = (data) => {
  return call.put(`/mgmt/version_management/updateVersion`, data);
};
/**
 * 获取最新的版本信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiGetNewVersion = () => {
  return call.get('/mgmt/version_management/queryByDate');
};

/**
 * 获取费率列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiGetChargeRuleList = ({ pageNumber, pageSize, payMode,name,regionCode,onShelves }) => {
  return call.get('/mgmt/charge-rule-template/listPage', { params: { pageNumber, pageSize,payMode,name,regionCode,onShelves } });
};
/**
 * 新增费率
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiAddChargeRuleTemplate = (data) => {
  return call.post('/mgmt/charge-rule-template/create', { ...data });
};
/**
 * 修改费率
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiUpdateChargeRuleTemplate = (data) => {
  return call.post('/mgmt/charge-rule-template/update', { ...data });
};
/**
 * 费率详情
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiDetailChargeRuleTemplate = ({ id }) => {
  return call.get('/mgmt/charge-rule-template/detail?id='+id);
};
// export const apiGetChargeRuleList = ({ pageNumber, pageSize, payMode,name,regionCode,onShelves }) => {
//   return call.get('/mgmt/charge-rule-template/listPage', { params: { pageNumber, pageSize,payMode,name,regionCode,onShelves } });
// };
/**
 * 删除费率
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiDeleteChargeRuleTemplate = ({ id }) => {
  return call.post('/mgmt/charge-rule-template/delete?id='+id, { id:id });
};
/**
 * 获取所有角色
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_all_roles=()=>{
  return call.get(`/auth/auth/getAllRoles`)
}
/**
 * 获取角色列表
 * @param pageNum
 * @param pageSize
 * @param name
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_roles_list=({pageNum,pageSize,name,type})=>{
  let path=`/auth/auth/queryRoleManagementList?pageNum=${pageNum}&pageSize=${pageSize}`
  if(name){
    path+=`&name=${name}`
  }
  if(type){
    path+=`&type=${type}`
  }
  return call.get(path)
}
/**
 * 用户激活/冻结
 * @param userId
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_user_freeze=({userId,status})=>{
  return call.get(`/auth/auth/activeOrFreezUser?userId=${userId}&status=${status}`)
}
/**
 * 用户管理列表获取
 * @param pageNum
 * @param pageSize
 * @param phoneNumber
 * @param userName
 * @param roleId
 * @param organization
 * @param deleteFlag
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_user_list=({pageNum,pageSize,phoneNumber,userName,roleId,organization,deleteFlag})=>{
  let path=`/auth/auth/queryUserManagementList?pageNum=${pageNum}&pageSize=${pageSize}`
  if(phoneNumber){
    path+=`&phoneNumber=${phoneNumber}`
  }
  if(userName){
    path+=`&userName=${userName}`
  }
  if(roleId){
    path+=`&roleId=${roleId}`
  }
  if(organization){
    path+=`&organization=${organization}`
  }
  if(deleteFlag){
    path+=`&deleteFlag=${deleteFlag}`
  }
  return call.get(path)
}
/**
 * 添加用户
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_add_user=(data)=>{
  return call.post(`/auth/auth/addUserManagement`,data);
}
/**
 * 编辑用户
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_edit_user=(data)=>{
  return call.post(`/auth/auth/updateUserManagement`,data);
}
export const async_agent_tree = () => {
  return call.get('/mgmt/agents/agentTreeNew');
};
/**
 * 获取默认密码
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_default_password=()=>{
  return call.post(`/auth/auth/getDefaultPassword`)
}
/**
 * 编辑用户弹窗详情获取
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_user_detail=(userId)=>{
  return call.get(`/auth/auth/getUpdateUserListByUserId?userId=${userId}`)
}
/**
 *用户管理-详情
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_user=(userId)=>{
  return call.get(`/auth/auth/getUserManagementById?userId=${userId}`)
}
/**
 * 获取用户优惠券
 * @param userId
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_coupon=({userId,status})=>{
  return call.get(`/mgmt/coupon-templates/getCouponInfosByUserId?userId=${userId}&status=${status}`)
}
/**
 * 充电记录
 * @param pageNum
 * @param pageSize
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_charging_record=({pageNum,pageSize,userId})=>{
  return call.get(`/auth/auth/getChargeRecordListByUserId?pageNum=${pageNum}&pageSize=${pageSize}&userId=${userId}`)
}
/**
 * 充值记录
 * @param pageNum
 * @param pageSize
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_recharge_record=({pageNum,pageSize,userId})=>{
  return call.get(`/auth/auth/getRechargeRecordListByUserId?pageNum=${pageNum}&pageSize=${pageSize}&userId=${userId}`)
}
/**
 * 提现记录
 * @param pageNum
 * @param pageSize
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_withdrawal_record=({pageNum,pageSize,userId})=>{
  return call.get(`/auth/auth/getWithdrawRecordListByUserId?pageNum=${pageNum}&pageSize=${pageSize}&userId=${userId}`)
}
/**
 * 获取权限数据
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_resource_tree=()=>{
  return call.get(`/auth/auth/getResourceNewTree`)
}
/**
 * 通过角色id获取权限
 * @param roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_get_role_by_id=(roleId)=>{
  return call.get(`/auth/auth/getRoleListById?roleId=${roleId}`)
}
/**
 * 新增角色
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_add_role=(data)=>{
  return call.post(`/auth/auth/addRoleManagement`,data);
}
/**
 * 编辑角色
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_edit_role=(data)=>{
  return call.post(`/auth/auth/updateRoleManagement`,data);
}
/**
 * 删除角色
 * @param roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_del_role=(roleId)=>{
  return call.delete(`/auth/auth/deleteRoleManagement?roleId=${roleId}`)
}
/**
 * 通过用户id重置密码
 * @param userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_reset_pwd=(userId)=>{
  return call.post(`/auth/auth/ResetPassword?userId=${userId}`)
}
<template>
  <el-dialog
    :model-value="show"
    :custom-class="`fc-dialog ${custom_class}`"
    :width="width"
    :before-close="on_before_close_handler"
  >
    <template #title>
      <div class="title-bar"></div>
      <span>{{title}}</span>
    </template>
    <el-form
      ref="form_ele"
      :rules="rules"
      :model="form"
      :label-width="label_width"
      :label-position="labelPosition"
      @resetFieldsFun="reset_fields_fn"
      @submit.prevent
    >
      <slot name="form" :data="form" />
    </el-form>
    <template #footer v-if="need_footer">
      <span class="fc-dialog-footer">
        <el-button
          v-if="show_cancel"
          @click="on_before_close_handler"
        >
          {{cancel_text}}
        </el-button>
        <el-button
          v-if="show_primary_btn"
          type="primary"
          :loading=submitting
          @click="on_submit_handler"
        >
          {{submit_text}}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  title: { type: String, default: () => '' },
  show: { type: Boolean, default: false },
  width: { type: Number, default: 720 },
  label_width: { type: String, default: '144px' },
  rules: { type: Object, default: () => null },
  default_data: { type: Object, default: null },
  labelPosition: { type: String, default: 'top' },
  need_footer: { type: Boolean, default: true },
  custom_class: { type: String, default: () => '' },
  show_primary_btn: { type: Boolean, default: true },
  cancel_text: { type: String, default: () => '取 消' },
  submit_text: { type: String, default: () => '确 认' },
  show_cancel: { type: Boolean, default: true }
});
const emits = defineEmits(['close', 'submit']);

// Mark: - Common
const form = ref({});
const form_ele = ref(null);
const submitting = ref(false);

// Mark: - Form
watch(
  () => props.show,
  (new_value) => {
    if (!new_value) return;
    const _update = { ...form.value };
    // 当dialog show的时候，重置form所有属性
    for (const key in _update) {
      _update[key] = '';
    }
    // 若配置了default_data， 用default_data回填form所有属性
    if (props.default_data) {
      for (const key in props.default_data) {
        _update[key] = props.default_data[key];
      }
    }
    submitting.value = false;
    // 重置el-form中的fields （第一次打开el-dialog后才会渲染form，所以需要判断form_ele是否存在）
    if (form_ele.value) {
      form_ele.value.resetFields();
    }

    form.value = { ..._update };
  }
);

// Mark: - Methods
const on_before_close_handler = () => {
  submitting.value = false;
  form.value = {};
  emits('close');
};

const on_submit_handler = () => {
  form_ele.value.validate(valid => {
    if (!valid) return;
    submitting.value = true;
    emits('submit', {
      form: form.value,
      callback: () => {
        submitting.value = false;
        // form.value = {};
      }
    });
  });
};

const reset_fields_fn = () => {
  if (form_ele.value) {
    form_ele.value.resetFields();
    form.value = {};
  }
};

// Mark: - Expose
const update_form = (key, value) => {
  form.value = key ? { ...form.value, [key]: value } : value;
};

const stop_loading = () => {
  submitting.value = false;
};

const get_form_data = () => {
  return { ...form.value };
};

defineExpose({ update_form, stop_loading, get_form_data });

</script>
<style lang="scss">
.fc-dialog {
  .el-dialog__header {
    padding: var(--charging-pile-size-padding);
    font-size: 1.6rem;
    font-weight: 400;
    color: #4C4C4C;
    display: flex;
    align-items: center;
    
    .title-bar {
      background-color: var(--charging-pile-color-primary);
      width: .4rem;
      height: 1.8rem;
      background: #3F93FF;
      border-radius: .2rem;
      margin-right: .8rem;
    }
  }
  .el-dialog__body {
    padding: var(--charging-pile-size-large-padding);
  }
}
</style>

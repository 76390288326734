import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export const use_main_redirect = (module_key) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  onMounted(() => {
    // 检测当前页面是否为模块根路径，
    // 若为根路径，自动匹配跳转到当前用户拥有权限的第一个子路由
    // 不使用 vue-router 的 redirect 配置防止用户权限缺失的情况跳转到非法页面
    if (route.path === `/${module_key}`) {
      const menus = store.state.navigator.visible_menus;
      const main_node = menus.find(m => m.path === route.path);
      if (!main_node) {
        router.push(`/permission-deny`);
      } else if (main_node.children && main_node.children.lenth !== 0) {
        router.push(`/${module_key}/${main_node.children[0].path}`);
      }
    }
  });
};

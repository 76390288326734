<template>
  <div class="charging-pile-app">
    <router-view></router-view>
    <!-- 图片预览器 -->
    <fcImagePreview :ref="rf => store.state.imagePreviewRef = rf" />
    <fcImagePreviewNew :ref="rf => store.state.imagePreviewNewRef = rf" />
    <!-- <fcVideoPreview :ref="rf => store.state.videoPreviewRef = rf" /> -->
  </div>
</template>
<script setup>
import fcImagePreview from '@/components/fc-image-preview';
import fcImagePreviewNew from '@/components/fc-image-preview-new'
// import fcVideoPreview from '@/components/fc-video-preview';
import { useStore } from 'vuex';

const store = useStore();
</script>
<style>
.charging-pile-app {
  height: 100vh;
  overflow: hidden;
}
</style>

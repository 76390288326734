<template>
  <div class="home-table">
    <!-- title -->
    <div class="home-card-title home-table-item" style="height: 2.4rem">

      <el-tooltip
        class="box-item"
        effect="dark"
        :content="titleTips"
        placement="top"
      >
        <span>{{title}}</span>
      </el-tooltip>
      <mySwitch :data="range" @update:data="on_range_data_changed_handler" />
    </div>
    <!-- header -->
    <div class="home-table-item home-table-header">
      <div class="header-cover rank">
        <div class="header">排名</div>
      </div>
      <div class="header-cover content">
        <div class="header">{{content_header}}</div>
      </div>
      <div class="header-cover value">
        <div class="header">{{value_header}}</div>
      </div>
    </div>
    <!-- data -->
    <div
      class="home-table-item home-table-value-row"
      v-for="(item, index) in data"
      :key="index"
    >
      <!-- rank -->
      <div class="rank-value-cover">
        <div :class="{
          'rank-circle': true,
          'first': index === 0,
          'second': index === 1,
          'third': index === 2
        }">{{index + 1}}</div>
      </div>
      <!-- content -->
      <el-tooltip
          class="box-item"
          effect="dark"
          :content="item.content"
          placement="top-start"
      >
        <div class="content-value-cover">
          {{item.content}}
        </div>
      </el-tooltip>
      <!-- value -->
      <div class="value-value-cover">
        {{item.value}}
      </div>
    </div>
    <div
      v-if="data.length === 0"
      class="home-table-item empty-content"
    >
      数据为空
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import mySwitch from '../month-year-switch';

defineProps({
  title: { type: String, default: () => '' },
  content_header: { type: String, default: () => '' },
  value_header: { type: String, default: () => '' },
  data: { type: Array, default: () => [] },
  titleTips: { type: String, default: () => '' }
});

const emit = defineEmits(['range-change']);

const range = ref('month');
const on_range_data_changed_handler = val => {
  range.value = val;
  emit('range-change');
};

defineExpose({ range });

</script>
<style lang="scss" scoped>
$--p-home-table-itme-height: calc(100% / 7);

.home-table {
  height: 100%;

  .home-table-item {
    height: $--p-home-table-itme-height;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;

    &.empty-content {
      margin-top: 1.2rem;
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }

  .home-card-title {
    // position: relative;
  }

  .home-table-header {
    .header-cover {
      &.rank {
        width: 5.2rem;
      }

      &.value {
        width: 6.4rem;
        // text-align: end;
      }

      &.content {
        flex: 1;
        margin: 0 .8rem;
      }

    }

    .header {
      padding: .8rem;
      background-color: #F0F3F6;
      border-radius: 2px;
      font-size: 1.4rem;
      font-weight: 400;
      color: #666666;
      width: fit-content;
      // margin-right: 0;
    }
  }

  .home-table-value-row {

    .rank-value-cover {
      width: 5.2rem;

      .rank-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.8rem;
        height: 1.8rem;
        color: white;
        margin-left: 1.3rem;
        background-color: #BBC2C8;
        &.first {
          background-color: #FF3E3E;
        }
        &.second {
          background-color: #3F93FF;
        }
        &.third {
          background-color: #FF9D3E;
        }
      }
    }

    .content-value-cover {
      flex: 1;
      margin: 0 .8rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #333333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .value-value-cover {
      width: 6.4rem;
      font-weight: bold;
      color: #333333;
      text-align: end;
      padding-right: 1rem;
      font-size: 1.4rem;
    }
  }
}

</style>

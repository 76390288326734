<template>
  <div class="home-single-color-bar-chart">
    <!-- title -->
    <div class="home-card-title home-table-item">
      <span>{{title}}</span>
      <mySwitch :data="range" @change="on_range_data_changed_handler" />
    </div>
    <div ref="chartRef" class="home-chart-container" />
  </div>
</template>
<script setup>
import { ref } from 'vue';
// import mySwitch from '../month-year-switch';
import * as echarts from 'echarts';
import mySwitch from '@/views/main/modules/home/components/week-year-switch';

const props = defineProps({
  name: { type: String, default: () => '数据' },
  title: { type: String, default: () => '' },
  color: { type: String, default: () => '#FF9D3E' },
  range: { type: String, default: () => 'week' }
});

const emit = defineEmits(['range-change']);

const on_range_data_changed_handler = val => {
  emit('range-change', val);
};

const init_options = data => {
  return {
    color: [props.color],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '0',
      right: '0',
      bottom: '3%',
      top: '32',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: data.xAxis_data,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: props.name,
        type: 'bar',
        barWidth: '50%',
        data: data.data
      }
    ]
  };
};

let chart = null;
const chartRef = ref();
const set_data = (data) => {
  if (!chartRef.value) return;
  if (!chart) {
    chart = echarts.init(chartRef.value);
  }
  chart.setOption(init_options(data));
};

defineExpose({ set_data });

</script>
<style lang="scss" scoped>
.home-single-color-bar-chart {
  height: 100%;

  .home-chart-container {
    height: calc(100% - 2.4rem);
    width: 100%;
  }
}
</style>
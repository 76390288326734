<template>
  <div class="home-card agents-card">
    <!-- home agents selector -->
    <!-- <div class="home-agents-selector-container">
      <chargingAgentSelector ref="agentSelectorRef" v-model:agentId="agentId" />
    </div> -->

    <el-row class="home-card-r1-c2-row" :gutter="20">
      <!-- 充电桩故障率排名TOP5 -->
      <el-col :span="12">
        <fcHomeTable 
          ref="faultTableRef"
          title="充电桩故障率排名TOP5" 
          content_header="名称"
          value_header="故障率"
          :data="gz_rank_data"
          @range-change="on_5_fault_range_change"
          title-tips="故障率 = 电站上报故障总数 / 所有故障总数"
        />
      </el-col>
      <!-- 充电桩使用率TOP5 -->
      <el-col :span="12">
        <fcHomeTable 
          ref="usageTableRef"
          title="充电桩使用率TOP5" 
          content_header="名称"
          value_header="使用率"
          :data="pile_usage_data"
          @range-change="on_5_usage_range_change"
          title-tips="使用率 = 充电总量 / ( 枪数量 X 7K X 24 )"
        />
      </el-col>
    </el-row>
    <el-row class="home-card-r1-c2-row" :gutter="20">
      <!-- 累计充电量 -->
      <el-col :span="12">
        <div class="half-chart">
          <fcHomeBarChart 
            ref="sumChargingRef"
            :title="charging_title"
            :range="charging_capacity_trend_period"
            @range-change="on_charging_capacity_range_change_handler"
          />
        </div>
        <div class="half-chart">
          <fcHomeBarChart 
            ref="sumChargingCoutRef"
            :title="charging_cout_title"
            :range="charging_cout_period"
            color="#3F93FF"
            @range-change="on_charging_cout_range_change_handler"
          />
        </div>
      </el-col>
      <el-col :span="12">
        <fcHomeTable 
          ref="volumnTableRef"
          title="充电桩营收TOP5" 
          content_header="名称"
          value_header="营收"
          :data="top_5_volumn_stations_data"
          @range-change="on_5_volumn_range_change"
          title-tips="通过订单消费统计"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
// import chargingAgentSelector from '@/charging-components/charging-agent-selector';
import fcHomeTable from '../home-table';
import fcHomeBarChart from '../single-color-bar-chart';
import moment from 'moment';
import { useStore } from 'vuex';

/* Common */
const store = useStore();
const agentId = ref(null);
// const agentSelectorRef = ref();
watch(
  () => agentId.value,
  () => {
    store.dispatch('home/sync_region_top_5_fault');
    store.dispatch('home/sync_region_top_5_usage');
  }
);

/* 营收Top5 */
const volumnTableRef = ref();
const top_5_volumn_stations_data = computed(() => store.state.home.top_5_volumn_stations_data);
const on_5_volumn_range_change = () => {
  const period_type = volumnTableRef.value.range;
  store.state.home.top_5_volumn_stations_peroid = period_type;
  store.dispatch('home/sync_volumn_stations');
};

/* 故障Top5 */
const faultTableRef = ref();
const gz_rank_data = computed(() => store.state.home.top_5_fault);
const on_5_fault_range_change = () => {
  const period_type = faultTableRef.value.range;
  store.state.home.top_5_fault_period = period_type;
  store.dispatch('home/sync_region_top_5_fault');
};

/* 充电桩使用率 */
const usageTableRef = ref();
const pile_usage_data = computed(() => store.state.home.top_5_usage);
const on_5_usage_range_change = () => {
  const period_type = usageTableRef.value.range;
  store.state.home.top_5_usage_period = period_type;
  store.dispatch('home/sync_region_top_5_usage');
};

const xAxis_data = [
  moment().subtract(0, 'days').format('MM-DD'),
  moment().subtract(1, 'days').format('MM-DD'),
  moment().subtract(2, 'days').format('MM-DD'),
  moment().subtract(3, 'days').format('MM-DD'),
  moment().subtract(4, 'days').format('MM-DD'),
  moment().subtract(5, 'days').format('MM-DD'),
  moment().subtract(6, 'days').format('MM-DD')
  // moment().subtract(7, 'days').format('MM-DD')
].reverse();
const xAxis_data_year = [
  moment().subtract(0, 'month').format('YYYY-MM'),
  moment().subtract(1, 'month').format('YYYY-MM'),
  moment().subtract(2, 'month').format('YYYY-MM'),
  moment().subtract(3, 'month').format('YYYY-MM'),
  moment().subtract(4, 'month').format('YYYY-MM'),
  moment().subtract(5, 'month').format('YYYY-MM'),
  moment().subtract(6, 'month').format('YYYY-MM'),
  moment().subtract(7, 'month').format('YYYY-MM'),
  moment().subtract(8, 'month').format('YYYY-MM'),
  moment().subtract(9, 'month').format('YYYY-MM'),
  moment().subtract(10, 'month').format('YYYY-MM'),
  moment().subtract(11, 'month').format('YYYY-MM')
  // moment().subtract(12, 'month').format('YYYY-MM')
].reverse();
/* 累计充电量 */
const sumChargingRef = ref();
const charging_title = computed(() => {
  return store.state.home.charging_capacity_trend_period === 'week'
    ? '过去7日累计充电量'
    : '过去12月累计充电量';
});
const charging_capacity_trend_period = computed(() => store.state.home.charging_capacity_trend_period);
const on_charging_capacity_range_change_handler = (val) => {
  store.state.home.charging_capacity_trend_period = val;
  store.dispatch('home/sync_charging_capacity_trend');
};
watch(
  () => store.state.home.charging_capacity_trend,
  () => {
    const params = { 
      xAxis_data: store.state.home.charging_capacity_trend_period === 'week'
        ? xAxis_data
        : xAxis_data_year, 
      data: store.state.home.charging_capacity_trend 
    };
    sumChargingRef.value.set_data(params);
  }
);

/* 累计充电次数 */
const sumChargingCoutRef = ref();
const charging_cout_title = computed(() => {
  return store.state.home.charging_cout_period === 'week'
    ? '过去7日累计充电次数'
    : '过去12月累计充电次数';
});
const charging_cout_period = computed(() => store.state.home.charging_cout_period);
const on_charging_cout_range_change_handler = (val) => {
  store.state.home.charging_cout_period = val;
  store.dispatch('home/sync_region_charging_cout');
};
watch(
  () => store.state.home.charging_cout_data,
  () => {
    const params = { 
      xAxis_data: store.state.home.charging_cout_period === 'week'
        ? xAxis_data
        : xAxis_data_year, 
      data: store.state.home.charging_cout_data 
    };
    sumChargingCoutRef.value.set_data(params);
  }
);

</script>
<style lang="scss" scoped>
.home-card.agents-card {
  height: 100%;

  .home-agents-selector-container {
    height: 4.4rem;
    border-bottom: 1px solid #F0F3F6;
  }

  .home-card-r1-c2-row {
    // height: calc(50% - 2.2rem);
    height: 50%;
    padding: .8rem;
  }
}
.half-chart {
  height: calc(50% - 0.8rem);
}
.half-chart + .half-chart {
  margin-top: 1.6rem;
}
</style>
<template>
  <div :class="main_class">
    <mainNavigator />
    <div class="charging-pile-main-container">
      <mainHeader />
      <router-view v-slot="{Component, route}">
        <keep-alive :include="includesComponents">
          <component v-bind:is="Component" :key="route.fullPath"></component>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: 'main'
}
</script>
<script setup>
import {computed, onActivated, onDeactivated, onMounted, onUpdated} from 'vue';
import { useStore } from 'vuex';
import mainHeader from './components/header';
import mainNavigator from './components/navigator';
import { location_types } from '@/store/location.store.js';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();

const includesComponents=computed(()=>store.state.tabList.filter((item)=>item.com_name).map(item=>{
  return item.com_name
}))
const is_collapse = computed(() => store.state.navigator.is_collapse);
const main_class = computed(() => {
  return {
    'charging-pile-main': true,
    'is-collapsed': is_collapse.value
  };
});
onMounted(() => {
  // init
  store.dispatch('sync_user_profile');
  store.dispatch('init_meta_data');
  store.dispatch('init_all_roles');

  // TODO: - 集成登录后，获取用户信息，将用户信息中地址部分写入 region
  store.commit(location_types.init_map_instance.path);
  store.dispatch('init_tab_data')
  // TODO: - 如果用户访问根路由(route.path === '/') 检索当前用户拥有权限的第一个页面进行跳转
  if (route.fullPath === '/') {
    if (store.state.navigator.visible_menus.length > 0) {
      const menu = store.state.navigator.visible_menus[0];
      const module_path = menu.path;
      const sub_module_path = menu.children ? menu.children[0].path : null;
      if (sub_module_path) {
        router.push(`${module_path}/${sub_module_path}`);
      } else {
        router.push(`/index`);
      }
    } else {
      router.push('/permission-deny');
    }
  }else{

  }
});
onActivated(() => {
  // 调用时机为首次挂载
  // 以及每次从缓存中被重新插入时
})

onDeactivated(() => {
  // 在从 DOM 上移除、进入缓存
  // 以及组件卸载时调用
})
</script>
<style>
@import '@/components/fc-status-form-item/fc-status-form-item.scss';

.charging-pile-main {
  /* header variable */
  --charging-pile-size-header-height: 4.4rem;
  /* navigator variable */
  --charging-pile-size-navigator-width: 18rem;
  --charging-pile-size-navigator-collapsed-width: 6.4rem;
  --charging-pile-color-navigator-background: var(--charging-pile-dark-background-color);
  --charging-pile-size-navigator-collapse-button-height: 3.2rem;
  --charging-pile-size-profile-wrapper-height: 10rem;
  --charging-pile-navigator-logo-height: 18rem;
/* --charging-pile-navigator-logo-height: 11rem;*/
}
</style>
<style lang="scss">
.charging-pile-main {
  height: 100%;
  width: 100%;
  display: flex;
  .charging-pile-main-container {
    // display: flex;
    transition: width .3s;
    width: calc(100% - var(--charging-pile-size-navigator-width));
    height:100%;

    .charging-pile-module-index {
      // width: calc(100% - var(--charging-pile-size-navigator-width));
      transition: width .3s;
      // background-color: var(--charging-pile-light-background-color);
      height: calc(100% - var(--charging-pile-size-header-height));
    }
  }

  &.is-collapsed {
    .charging-pile-main-container {
      width: calc(100% - var(--charging-pile-size-navigator-collapsed-width));
    }
  }
}

</style>

<!-- .charging-pile-module-index -->
<style lang="scss">
.charging-pile-module-index {
  padding: var(--charging-pile-size-large-padding);

  &.no-padding {
    padding: 0;
  }

}
</style>

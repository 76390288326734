<template>
  <TemplateDialog v-if="dialog_visible" :show="dialog_visible" @close-dialog="changeDoDialog(false)">
    <template v-slot:titleText>
      修改密码
    </template>
    <template v-slot:bodyBox>
      <div class="dialog-body">
        <el-form :model="form" label-width="0" ref="formRef" :rules="rules">
          <fcLoginFormItem
              :data="profile.phoneNumber"
              :icon_src="require('@/assets/icons/login/login-phone.png')"
              style="margin-top: 4.5rem"
              :isDisable="true"
              @sms="on_sms_handler"
              :loginType="1"
          />
          <fcLoginFormItem
              prop="code"
              :is_code="true"
              :data="form.code"
              :whiteClass="true"
              :icon_src="require('@/assets/icons/login/login-code.png')"
              style="margin-top: 3rem"
              v-if="1"
              placeholder="请输入验证码"
              @data-change="c => form.code = c"
          />
          <fcLoginFormItem
              prop="pwd"
              :is-password="true"
              :whiteClass="true"
              :data="form.pwd"
              :type="'password'"
              :icon_src="require('@/assets/icons/login/login-pwd.png')"
              style="margin-top: 3rem"
              placeholder="请输入密码"
              @data-change="c => form.pwd = c"
          />
        </el-form>
      </div>
    </template>
    <template v-slot:footerBox>
      <div class="foot-box">
        <el-button type="primary" style="width: 80%"  round @click="handleTodo">保存</el-button>
      </div>
    </template>
  </TemplateDialog>
</template>
<script setup>
import {computed, ref} from "vue";
import TemplateDialog from "@/views/main/modules/maintenance/dialog/template-dialog.vue";
import fcLoginFormItem from '../../../login/components/login-input/index.vue';
import {useInterval} from "@vueuse/core";
import regex_util, {regex_keys} from "@/utils/regex.util";
import {ElMessage} from "element-plus";
import {reset_psd, sms} from "@/api/auth.api";
import {useStore} from "vuex";

const rules = {
  phone: { required: true, message: '请输入手机号', trigger: 'blur' },
  code: { required: true, message: '请输入验证码', trigger: 'blur' },
  pwd: {required: true, message: '请输入密码', trigger: 'blur'}
};
const form=ref({phone:''});
const formRef=ref();
const dialog_visible=ref(false);
const store = useStore();
const profile = computed(() => {
  return store.state.profile;
});
const changeDoDialog=(flag)=>{
  dialog_visible.value=flag
}
const on_sms_handler = async () => {
  const is_valid_phone = regex_util.valid(profile.value.phoneNumber, regex_keys.mobile_phone);
  if (!is_valid_phone) {
    ElMessage.error('请输入合法的手机号');
    return;
  }
  try {
    await sms(profile.value.phoneNumber);
    ElMessage.success('验证码已通过短信发送');
  } catch (error) {
    console.error(error);
  }
};
const open_with_data = (_data = {}) => {
  changeDoDialog(true);
};
const handleTodo=()=>{
  formRef.value.validate(async valid=>{
    if (!valid) return;
    try {
      const res = await reset_psd({
        password:form.value.pwd,
        phoneNumber:profile.value.phoneNumber,
        verificationCode: form.value.code,
      })
      if(res&&res.code&&res.code===200){
        ElMessage.success('密码修改成功！')
        form.value={ phone: '' }
        changeDoDialog(false);
      }else {
        ElMessage.error(res?.message?.message||res?.message||'保存失败')
      }
    }catch (e){
      ElMessage.error('保存失败');
    }
  })
}
defineExpose({ open_with_data });
</script>
<style lang="scss" scoped>
.dialog-body{
  padding: 0 4rem;
}
.foot-box{
  text-align: center;
  margin-bottom: 4rem;
}
</style>